export class Utils {
  static getTokenFromLocal() {
    let user = JSON.parse(localStorage.getItem("user"));
    return user.authToken;
  }

  static getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  static isLoggedIn() {
    if (localStorage.getItem("token")) {
      return true;
    }
    return false;
  }

  static getqueryParam(param) {
    let url = window.location.href;
    let token = url.split("?", 2)[1];
    if (token === undefined || token === null || token === "") {
      return undefined;
    }
    token = token.split(param + "=", 2)[1];
    if (token === undefined || token === null || token === "") {
      return undefined;
    }
    return token;
  }

  static loadScript(url, callback) {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      //IE
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      //Others
      script.onload = function () {
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}
