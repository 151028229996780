export const mobileOsChoices = [
  {"id": "#ui-id-5", "name":"android"},
  {"id": "#ui-id-6", "name":"apple iOS"},
  {"id": "#ui-id-7", "name":"blackberry"},
  {"id": "#ui-id-8", "name":"windows mobile"},
  {"id": "#ui-id-9", "name":"other"}
]

export const categoryChoices = [
   {
      "id":"",
      "name":"choose"
   },
   {
      "id":"150",
      "name":" antiques - by owner"
   },
   {
      "id":"149",
      "name":" appliances - by owner"
   },
   {
      "id":"135",
      "name":" crafts - by owner"
   },
   {
      "id":"191",
      "name":"atvs, utvs, snowmobiles - by owner"
   },
   {
      "id":"122",
      "name":"auto parts - by owner"
   },
   {
      "id":"203",
      "name":"auto wheels &amp; tires - by owner"
   },
   {
      "id":"208",
      "name":"aviation - by owner"
   },
   {
      "id":"107",
      "name":"baby kid stuff - by owner"
   },
   {
      "id":"42",
      "name":"barter - by owner"
   },
   {
      "id":"197",
      "name":"bicycle parts - by owner"
   },
   {
      "id":"68",
      "name":"bicycles - by owner"
   },
   {
      "id":"201",
      "name":"boat parts - by owner"
   },
   {
      "id":"119",
      "name":"boats - by owner"
   },
   {
      "id":"92",
      "name":"books &amp; magzines - by owner"
   },
   {
      "id":"134",
      "name":"business/commercial - by owner"
   },
   {
      "id":"145",
      "name":"cars/trucks - by owner"
   },
   {
      "id":"117",
      "name":"cds / dvds / vhs - by owner"
   },
   {
      "id":"153",
      "name":"cell phones - by owner"
   },
   {
      "id":"94",
      "name":"clothing/accessories - by owner"
   },
   {
      "id":"95",
      "name":" collectibles- by owner"
   },
   {
      "id":"199",
      "name":"computer parts - by owner"
   },
   {
      "id":"7",
      "name":"computers - by owner"
   },
   {
      "id":"96",
      "name":"electronics - by owner"
   },
   {
      "id":"133",
      "name":"farm & garden - by owner"
   },
   {
      "id":"101",
      "name":"free stuff - by owner"
   },
   {
      "id":"141",
      "name":"furniture - by owner"
   },
   {
      "id":"73",
      "name":"garage &amp; moving sales - by owner"
   },
   {
      "id":"5",
      "name":"general for sale - by owner"
   },
   {
      "id":"152",
      "name":"health and beauty - by owner"
   },
   {
      "id":"193",
      "name":"heavy equipment - by owner"
   },
   {
      "id":"97",
      "name":"household items - by owner"
   },
   {
      "id":"120",
      "name":"jewelry - by owner"
   },
   {
      "id":"136",
      "name":"materials - by owner"
   },
   {
      "id":"195",
      "name":"motorcycle parts - by owner"
   },
   {
      "id":"69",
      "name":"motorcycles/scooters - by owner"
   },
   {
      "id":"98",
      "name":"musical instruments - by owner"
   },
   {
      "id":"137",
      "name":"photo/video - by owner"
   },
   {
      "id":"124",
      "name":"rvs - by owner"
   },
   {
      "id":"93",
      "name":"sporting goods - by owner"
   },
   {
      "id":"44",
      "name":"tickets - by owner"
   },
   {
      "id":"118",
      "name":"tools - by owner"
   },
   {
      "id":"132",
      "name":"toys &amp; games - by owner"
   },
   {
      "id":"205",
      "name":"trailers - by owner"
   },
   {
      "id":"151",
      "name":"video gaming - by owner"
   },
   {
      "id":"20",
      "name":"wanted - by owner"
   }
];
export const offerupCategoryChoices = [
   {
      "id":"",
      "name":"choose"
   },
   {
      "id":"Antiques",
      "name":"Antiques"
   },
   {
      "id":"Appliances",
      "name":"Appliances"
   },
   {
      "id":"rts & Crafts",
      "name":"Arts & Crafts"
   },
   {
      "id":"Audio Equipment",
      "name":"Audio Equipment"
   },
   {
      "id":"Auto Parts",
      "name":"Auto Parts"
   },
   {
      "id":"Baby & Kids",
      "name":"Baby & Kids"
   },
   {
      "id":"Beauty & Health",
      "name":"Beauty & Health"
   },
   {
      "id":"Bicycles",
      "name":"Bicycles"
   },
   {
      "id":"Boats & Marine",
      "name":"Boats & Marine"
   },
   {
      "id":"Books & Magazines",
      "name":"Books & Magazines"
   },
   {
      "id":"Business Equipment",
      "name":"Business Equipment"
   },
   {
      "id":"Campers & RVs",
      "name":"Campers & RVs"
   },
   {
      "id":"Cars & Trucks",
      "name":"Cars & Trucks"
   },
   {
      "id":"CDs & DVDs",
      "name":"CDs & DVDs"
   },
   {
      "id":"Cell Phones",
      "name":"Cell Phones"
   },
   {
      "id":"Clothing & Shoes",
      "name":"Clothing & Shoes"
   },
   {
      "id":"Collectibles",
      "name":"Collectibles"
   },
   {
      "id":"Computer Equipment",
      "name":"Computer Equipment"
   },
   {
      "id":"Electronics",
      "name":"Electronics"
   },
   {
      "id":"Farming",
      "name":"Farming"
   },
   {
      "id":"Games & Toys",
      "name":"Games & Toys"
   },
   {
      "id":"General",
      "name":"General"
   },
   {
      "id":"Home & Garden",
      "name":"Home & Garden"
   },
   {
      "id":"Household",
      "name":"Household"
   },
   {
      "id":"Jewellery & Accessories",
      "name":"Jewellery & Accessories"
   },
   {
      "id":"Motorcycles",
      "name":"Motorcycles"
   },
   {
      "id":"Musical Instruments",
      "name":"Musical Instruments"
   },
   {
      "id":"Pet Supplies",
      "name":"Pet Supplies"
   },
   {
      "id":"Photography",
      "name":"Photography"
   },
   {
      "id":"Sports & Outdoors",
      "name":"Sports & Outdoors"
   },
   {
      "id":"Tickets",
      "name":"Tickets"
   },
   {
      "id":"Tools & Machinery",
      "name":"Tools & Machinery"
   },
   {
      "id":"TVs",
      "name":"TVs"
   },
   {
      "id":"Video Equipment",
      "name":"Video Equipment"
   },
   {
      "id":"Video Games",
      "name":"Video Games"
   }
];

export const postingCityChoices = [
   {
      "id":"abz",
      "name":"aberdeen,  GB"
   },
   {
      "id":"abi",
      "name":"abilene,  TX,  US"
   },
   {
      "id":"aca",
      "name":"acapulco,  MX"
   },
   {
      "id":"adl",
      "name":"adelaide,SA,AU"
   },
   {
      "id":"amd\" selected=\"",
      "name":"ahmedabad,  IN"
   },
   {
      "id":"cak",
      "name":"akron-canton,  OH,  US"
   },
   {
      "id":"aby",
      "name":"albany, GA,  GA,  US"
   },
   {
      "id":"alb",
      "name":"albany, NY,  NY,  US"
   },
   {
      "id":"abq",
      "name":"albuquerque,  NM,  US"
   },
   {
      "id":"alc",
      "name":"alicante,  ES"
   },
   {
      "id":"alt",
      "name":"allentown,  PA,  US"
   },
   {
      "id":"aoo",
      "name":"altoona,  PA,  US"
   },
   {
      "id":"ama",
      "name":"amarillo,  TX,  US"
   },
   {
      "id":"ame",
      "name":"ames, IA,  IA,  US"
   },
   {
      "id":"ams",
      "name":"amsterdam,  NL"
   },
   {
      "id":"anc",
      "name":"anchorage,  AK,  US"
   },
   {
      "id":"anp",
      "name":"annapolis,  MD,  US"
   },
   {
      "id":"aaa",
      "name":"ann arbor,  MI,  US"
   },
   {
      "id":"app",
      "name":"appleton,  WI,  US"
   },
   {
      "id":"ash",
      "name":"asheville,  NC,  US"
   },
   {
      "id":"jfn",
      "name":"ashtabula,  OH,  US"
   },
   {
      "id":"ahn",
      "name":"athens, GA,  GA,  US"
   },
   {
      "id":"ohu",
      "name":"athens, OH,  OH,  US"
   },
   {
      "id":"atl",
      "name":"atlanta,  GA,  US"
   },
   {
      "id":"aub",
      "name":"auburn,  AL,  US"
   },
   {
      "id":"akl",
      "name":"auckland,  NZ"
   },
   {
      "id":"aug",
      "name":"augusta,  GA,  US"
   },
   {
      "id":"aus",
      "name":"austin,  TX,  US"
   },
   {
      "id":"bcd",
      "name":"bacolod,  PH"
   },
   {
      "id":"bcs",
      "name":"baja sur,  MX"
   },
   {
      "id":"bak",
      "name":"bakersfield,  CA,  US"
   },
   {
      "id":"ibz",
      "name":"baleares,  ES"
   },
   {
      "id":"bal",
      "name":"baltimore,  MD,  US"
   },
   {
      "id":"bng",
      "name":"bangalore,  IN"
   },
   {
      "id":"bgl",
      "name":"bangladesh,  BD"
   },
   {
      "id":"bar",
      "name":"barcelona,  ES"
   },
   {
      "id":"brr",
      "name":"barrie,  ON,  CA"
   },
   {
      "id":"bsl",
      "name":"basel,  CH"
   },
   {
      "id":"bth",
      "name":"bath,  GB"
   },
   {
      "id":"btr",
      "name":"baton rouge,  LA,  US"
   },
   {
      "id":"btc",
      "name":"battle creek,  MI,  US"
   },
   {
      "id":"bpt",
      "name":"beaumont,  TX,  US"
   },
   {
      "id":"pek",
      "name":"beijing,  CN"
   },
   {
      "id":"bey",
      "name":"beirut,  LB"
   },
   {
      "id":"blf",
      "name":"belfast,  GB"
   },
   {
      "id":"bru",
      "name":"belgium,  BE"
   },
   {
      "id":"bel",
      "name":"belleville, ON,  ON,  CA"
   },
   {
      "id":"bli",
      "name":"bellingham,  WA,  US"
   },
   {
      "id":"cnf",
      "name":"belo horizonte,  BR"
   },
   {
      "id":"bji",
      "name":"bemidji,  MN,  US"
   },
   {
      "id":"bnd",
      "name":"bend,  OR,  US"
   },
   {
      "id":"ber",
      "name":"berlin,  DE"
   },
   {
      "id":"brn",
      "name":"bern,  CH"
   },
   {
      "id":"bbi",
      "name":"bhubaneswar,  IN"
   },
   {
      "id":"wnp",
      "name":"bicol region,  PH"
   },
   {
      "id":"bio",
      "name":"bilbao,  ES"
   },
   {
      "id":"bil",
      "name":"billings,  MT,  US"
   },
   {
      "id":"bgm",
      "name":"binghamton,  NY,  US"
   },
   {
      "id":"bhm",
      "name":"birmingham, AL,  AL,  US"
   },
   {
      "id":"bhx",
      "name":"birmingham, UK,  GB"
   },
   {
      "id":"bis",
      "name":"bismarck,  ND,  US"
   },
   {
      "id":"vpi",
      "name":"blacksburg,  VA,  US"
   },
   {
      "id":"bln",
      "name":"bloomington, IL,  IL,  US"
   },
   {
      "id":"bmg",
      "name":"bloomington, IN,  IN,  US"
   },
   {
      "id":"boi",
      "name":"boise,  ID,  US"
   },
   {
      "id":"lpb",
      "name":"bolivia,  BO"
   },
   {
      "id":"blq",
      "name":"bologna,  IT"
   },
   {
      "id":"bnc",
      "name":"boone,  NC,  US"
   },
   {
      "id":"bod",
      "name":"bordeaux,  FR"
   },
   {
      "id":"bos",
      "name":"boston,  MA,  US"
   },
   {
      "id":"bou",
      "name":"boulder,  CO,  US"
   },
   {
      "id":"blg",
      "name":"bowling green,  KY,  US"
   },
   {
      "id":"bzn",
      "name":"bozeman,  MT,  US"
   },
   {
      "id":"brd",
      "name":"brainerd,  MN,  US"
   },
   {
      "id":"bfd",
      "name":"brantford,  ON,  CA"
   },
   {
      "id":"bsb",
      "name":"brasilia,  BR"
   },
   {
      "id":"brm",
      "name":"bremen,  DE"
   },
   {
      "id":"bri",
      "name":"brighton,  GB"
   },
   {
      "id":"bne",
      "name":"brisbane,  QLD,  AU"
   },
   {
      "id":"brs",
      "name":"bristol,  GB"
   },
   {
      "id":"rns",
      "name":"brittany,  FR"
   },
   {
      "id":"bro",
      "name":"brownsville,  TX,  US"
   },
   {
      "id":"bwk",
      "name":"brunswick, GA,  GA,  US"
   },
   {
      "id":"bud",
      "name":"budapest,  HU"
   },
   {
      "id":"bue",
      "name":"buenos aires,  AR"
   },
   {
      "id":"buf",
      "name":"buffalo,  NY,  US"
   },
   {
      "id":"sof",
      "name":"bulgaria,  BG"
   },
   {
      "id":"btm",
      "name":"butte,  MT,  US"
   },
   {
      "id":"cdz",
      "name":"cadiz,  ES"
   },
   {
      "id":"cdo",
      "name":"cagayan de oro,  PH"
   },
   {
      "id":"cns",
      "name":"cairns,  QLD,  AU"
   },
   {
      "id":"clg",
      "name":"calgary,  AB,  CA"
   },
   {
      "id":"cam",
      "name":"cambridge,  GB"
   },
   {
      "id":"tfn",
      "name":"canarias,  ES"
   },
   {
      "id":"cbr",
      "name":"canberra,  NSW,  AU"
   },
   {
      "id":"cap",
      "name":"cape cod,  MA,  US"
   },
   {
      "id":"cpt",
      "name":"cape town,  ZA"
   },
   {
      "id":"cym",
      "name":"cardiff,  GB"
   },
   {
      "id":"crb",
      "name":"caribbean,"
   },
   {
      "id":"cbo",
      "name":"cariboo,  BC,  CA"
   },
   {
      "id":"cat",
      "name":"catskills,  NY,  US"
   },
   {
      "id":"ceb",
      "name":"cebu,  PH"
   },
   {
      "id":"ced",
      "name":"cedar rapids,  IA,  US"
   },
   {
      "id":"aex",
      "name":"central LA,  LA,  US"
   },
   {
      "id":"cmu",
      "name":"central MI,  MI,  US"
   },
   {
      "id":"cnj",
      "name":"central NJ,  NJ,  US"
   },
   {
      "id":"csd",
      "name":"central SD,  SD,  US"
   },
   {
      "id":"chm",
      "name":"chambana,  IL,  US"
   },
   {
      "id":"ixc",
      "name":"chandigarh,  IN"
   },
   {
      "id":"chs",
      "name":"charleston,  SC,  US"
   },
   {
      "id":"crw",
      "name":"charleston, WV,  WV,  US"
   },
   {
      "id":"cha",
      "name":"charlotte,  NC,  US"
   },
   {
      "id":"uva",
      "name":"charlottesville,  VA,  US"
   },
   {
      "id":"chk",
      "name":"chatham-kent,  ON,  CA"
   },
   {
      "id":"cht",
      "name":"chattanooga,  TN,  US"
   },
   {
      "id":"chq",
      "name":"chautauqua,  NY,  US"
   },
   {
      "id":"ctu",
      "name":"chengdu,  CN"
   },
   {
      "id":"che",
      "name":"chennai,  IN"
   },
   {
      "id":"chi",
      "name":"chicago,  IL,  US"
   },
   {
      "id":"chc",
      "name":"chico,  CA,  US"
   },
   {
      "id":"chh",
      "name":"chihuahua,  MX"
   },
   {
      "id":"scl",
      "name":"chile,  CL"
   },
   {
      "id":"chl",
      "name":"chillicothe,  OH,  US"
   },
   {
      "id":"ckg",
      "name":"chongqing,  CN"
   },
   {
      "id":"chr",
      "name":"christchurch,  NZ"
   },
   {
      "id":"cin",
      "name":"cincinnati,  OH,  US"
   },
   {
      "id":"cjs",
      "name":"ciudad juarez,  MX"
   },
   {
      "id":"ckv",
      "name":"clarksville, TN,  TN,  US"
   },
   {
      "id":"cle",
      "name":"cleveland,  OH,  US"
   },
   {
      "id":"cvn",
      "name":"clovis-portales,  NM,  US"
   },
   {
      "id":"cst",
      "name":"college station,  TX,  US"
   },
   {
      "id":"cgn",
      "name":"cologne,  DE"
   },
   {
      "id":"bog",
      "name":"colombia,  CO"
   },
   {
      "id":"cos",
      "name":"colo springs,  CO,  US"
   },
   {
      "id":"cae",
      "name":"columbia,  SC,  US"
   },
   {
      "id":"cou",
      "name":"columbia, MO,  MO,  US"
   },
   {
      "id":"csg",
      "name":"columbus, GA,  GA,  US"
   },
   {
      "id":"col",
      "name":"columbus, OH,  OH,  US"
   },
   {
      "id":"cmx",
      "name":"comox valley,  BC,  CA"
   },
   {
      "id":"coo",
      "name":"cookeville,  TN,  US"
   },
   {
      "id":"cop",
      "name":"copenhagen,  DK"
   },
   {
      "id":"ycc",
      "name":"cornwall, ON,  ON,  CA"
   },
   {
      "id":"crp",
      "name":"corpus christi,  TX,  US"
   },
   {
      "id":"crv",
      "name":"corvallis,  OR,  US"
   },
   {
      "id":"cri",
      "name":"costa rica,  CR"
   },
   {
      "id":"nce",
      "name":"cote d'azur,  FR"
   },
   {
      "id":"cov",
      "name":"coventry,  GB"
   },
   {
      "id":"zag",
      "name":"croatia,  HR"
   },
   {
      "id":"cbg",
      "name":"cumberland val,  PA,  US"
   },
   {
      "id":"cwb",
      "name":"curitiba,  BR"
   },
   {
      "id":"dlc",
      "name":"dalian,  CN"
   },
   {
      "id":"dal",
      "name":"dallas,  TX,  US"
   },
   {
      "id":"dnv",
      "name":"danville,  VA,  US"
   },
   {
      "id":"drw",
      "name":"darwin,  NT,  AU"
   },
   {
      "id":"dvo",
      "name":"davao city,  PH"
   },
   {
      "id":"day",
      "name":"dayton,  OH,  US"
   },
   {
      "id":"dab",
      "name":"daytona beach,  FL,  US"
   },
   {
      "id":"dil",
      "name":"decatur, IL,  IL,  US"
   },
   {
      "id":"och",
      "name":"deep east TX,  TX,  US"
   },
   {
      "id":"dlw",
      "name":"delaware,  DE,  US"
   },
   {
      "id":"del",
      "name":"delhi,  IN"
   },
   {
      "id":"drt",
      "name":"del rio,  TX,  US"
   },
   {
      "id":"den",
      "name":"denver,  CO,  US"
   },
   {
      "id":"dby",
      "name":"derby,  GB"
   },
   {
      "id":"dsm",
      "name":"des moines,  IA,  US"
   },
   {
      "id":"det",
      "name":"detroit metro,  MI,  US"
   },
   {
      "id":"dvc",
      "name":"devon &amp; cornwall,  GB"
   },
   {
      "id":"sdq",
      "name":"dominican,  DO"
   },
   {
      "id":"dhn",
      "name":"dothan, AL,  AL,  US"
   },
   {
      "id":"drs",
      "name":"dresden,  DE"
   },
   {
      "id":"dub",
      "name":"dublin,  IE"
   },
   {
      "id":"dbq",
      "name":"dubuque,  IA,  US"
   },
   {
      "id":"dlh",
      "name":"duluth,  MN,  US"
   },
   {
      "id":"dnd",
      "name":"dundee,  GB"
   },
   {
      "id":"dud",
      "name":"dunedin,  NZ"
   },
   {
      "id":"dur",
      "name":"durban,  ZA"
   },
   {
      "id":"dus",
      "name":"dusseldorf,  DE"
   },
   {
      "id":"nwh",
      "name":"east anglia,  GB"
   },
   {
      "id":"eco",
      "name":"eastern CO,  CO,  US"
   },
   {
      "id":"nlo",
      "name":"eastern CT,  CT,  US"
   },
   {
      "id":"eky",
      "name":"eastern KY,  KY,  US"
   },
   {
      "id":"mnt",
      "name":"eastern montana,  MT,  US"
   },
   {
      "id":"enc",
      "name":"eastern NC,  NC,  US"
   },
   {
      "id":"esh",
      "name":"eastern shore,  MD,  US"
   },
   {
      "id":"ewv",
      "name":"eastern WV,  WV,  US"
   },
   {
      "id":"eid",
      "name":"east idaho,  ID,  US"
   },
   {
      "id":"eml",
      "name":"east midlands,  GB"
   },
   {
      "id":"eor",
      "name":"east oregon,  OR,  US"
   },
   {
      "id":"etx",
      "name":"east TX,  TX,  US"
   },
   {
      "id":"eau",
      "name":"eau claire,  WI,  US"
   },
   {
      "id":"qui",
      "name":"ecuador,  EC"
   },
   {
      "id":"edi",
      "name":"edinburgh,  GB"
   },
   {
      "id":"edm",
      "name":"edmonton,  AB,  CA"
   },
   {
      "id":"cai",
      "name":"egypt,  EG"
   },
   {
      "id":"elk",
      "name":"elko,  NV,  US"
   },
   {
      "id":"elm",
      "name":"elmira,  NY,  US"
   },
   {
      "id":"elp",
      "name":"el paso,  TX,  US"
   },
   {
      "id":"sal",
      "name":"el salvador,  SV"
   },
   {
      "id":"uae",
      "name":"emirates,  AE"
   },
   {
      "id":"eri",
      "name":"erie, PA,  PA,  US"
   },
   {
      "id":"ess",
      "name":"essen / ruhr,  DE"
   },
   {
      "id":"esx",
      "name":"essex,  GB"
   },
   {
      "id":"add",
      "name":"ethiopia,  ET"
   },
   {
      "id":"eug",
      "name":"eugene,  OR,  US"
   },
   {
      "id":"evv",
      "name":"evansville,  IN,  US"
   },
   {
      "id":"fai",
      "name":"fairbanks,  AK,  US"
   },
   {
      "id":"far",
      "name":"fargo,  ND,  US"
   },
   {
      "id":"fnm",
      "name":"farmington, NM,  NM,  US"
   },
   {
      "id":"fro",
      "name":"faro / algarve,  PT"
   },
   {
      "id":"fyv",
      "name":"fayetteville, AR,  AR,  US"
   },
   {
      "id":"fay",
      "name":"fayetteville, NC,  NC,  US"
   },
   {
      "id":"fgl",
      "name":"finger lakes,  NY,  US"
   },
   {
      "id":"hel",
      "name":"finland,  FI"
   },
   {
      "id":"flg",
      "name":"flagstaff,  AZ,  US"
   },
   {
      "id":"fnt",
      "name":"flint,  MI,  US"
   },
   {
      "id":"flr",
      "name":"florence,  IT"
   },
   {
      "id":"flo",
      "name":"florence, SC,  SC,  US"
   },
   {
      "id":"key",
      "name":"florida keys,  FL,  US"
   },
   {
      "id":"ftl",
      "name":"fortaleza,  BR"
   },
   {
      "id":"ftc",
      "name":"fort collins,  CO,  US"
   },
   {
      "id":"ftd",
      "name":"fort dodge,  IA,  US"
   },
   {
      "id":"fmy",
      "name":"fort myers,  FL,  US"
   },
   {
      "id":"fsm",
      "name":"fort smith,  AR,  US"
   },
   {
      "id":"fwa",
      "name":"fort wayne,  IN,  US"
   },
   {
      "id":"fra",
      "name":"frankfurt,  DE"
   },
   {
      "id":"abb",
      "name":"fraser valley,  BC,  CA"
   },
   {
      "id":"fdk",
      "name":"frederick,  MD,  US"
   },
   {
      "id":"ezf",
      "name":"fredericksburg,  VA,  US"
   },
   {
      "id":"fre",
      "name":"fresno,  CA,  US"
   },
   {
      "id":"fmc",
      "name":"ft mcmurray,  AB,  CA"
   },
   {
      "id":"fuk",
      "name":"fukuoka,  JP"
   },
   {
      "id":"anb",
      "name":"gadsden,  AL,  US"
   },
   {
      "id":"gnv",
      "name":"gainesville,  FL,  US"
   },
   {
      "id":"gls",
      "name":"galveston,  TX,  US"
   },
   {
      "id":"gva",
      "name":"geneva,  CH"
   },
   {
      "id":"gen",
      "name":"genoa,  IT"
   },
   {
      "id":"acc",
      "name":"ghana,  GH"
   },
   {
      "id":"gla",
      "name":"glasgow,  GB"
   },
   {
      "id":"gfl",
      "name":"glens falls,  NY,  US"
   },
   {
      "id":"goa",
      "name":"goa,  IN"
   },
   {
      "id":"ool",
      "name":"gold coast,  QLD,  AU"
   },
   {
      "id":"gld",
      "name":"gold country,  CA,  US"
   },
   {
      "id":"grx",
      "name":"granada,  ES"
   },
   {
      "id":"gfk",
      "name":"grand forks,  ND,  US"
   },
   {
      "id":"gil",
      "name":"grand island,  NE,  US"
   },
   {
      "id":"grr",
      "name":"grand rapids,  MI,  US"
   },
   {
      "id":"gtf",
      "name":"great falls,  MT,  US"
   },
   {
      "id":"ath",
      "name":"greece,  GR"
   },
   {
      "id":"grb",
      "name":"green bay,  WI,  US"
   },
   {
      "id":"gbo",
      "name":"greensboro,  NC,  US"
   },
   {
      "id":"gsp",
      "name":"greenville,  SC,  US"
   },
   {
      "id":"gnb",
      "name":"grenoble,  FR"
   },
   {
      "id":"gdl",
      "name":"guadalajara,  MX"
   },
   {
      "id":"bjx",
      "name":"guanajuato,  MX"
   },
   {
      "id":"can",
      "name":"guangzhou,  CN"
   },
   {
      "id":"gua",
      "name":"guatemala,  GT"
   },
   {
      "id":"gph",
      "name":"guelph,  ON,  CA"
   },
   {
      "id":"gpt",
      "name":"gulfport,  MS,  US"
   },
   {
      "id":"hfa",
      "name":"haifa,  IL"
   },
   {
      "id":"hfx",
      "name":"halifax,  NS,  CA"
   },
   {
      "id":"ham",
      "name":"hamburg,  DE"
   },
   {
      "id":"hml",
      "name":"hamilton,  ON,  CA"
   },
   {
      "id":"sou",
      "name":"hampshire,  GB"
   },
   {
      "id":"hnf",
      "name":"hanford,  CA,  US"
   },
   {
      "id":"hgh",
      "name":"hangzhou,  CN"
   },
   {
      "id":"haj",
      "name":"hannover,  DE"
   },
   {
      "id":"hrs",
      "name":"harrisburg,  PA,  US"
   },
   {
      "id":"shd",
      "name":"harrisonburg,  VA,  US"
   },
   {
      "id":"htf",
      "name":"hartford,  CT,  US"
   },
   {
      "id":"usm",
      "name":"hattiesburg,  MS,  US"
   },
   {
      "id":"hnl",
      "name":"hawaii,  HI,  US"
   },
   {
      "id":"cfl",
      "name":"heartland FL,  FL,  US"
   },
   {
      "id":"hdb",
      "name":"heidelberg,  DE"
   },
   {
      "id":"hln",
      "name":"helena,  MT,  US"
   },
   {
      "id":"hrm",
      "name":"hermosillo,  MX"
   },
   {
      "id":"hky",
      "name":"hickory,  NC,  US"
   },
   {
      "id":"rck",
      "name":"high rockies,  CO,  US"
   },
   {
      "id":"hhi",
      "name":"hilton head,  SC,  US"
   },
   {
      "id":"hij",
      "name":"hiroshima,  JP"
   },
   {
      "id":"hld",
      "name":"holland,  MI,  US"
   },
   {
      "id":"hkg",
      "name":"hong kong,  HK"
   },
   {
      "id":"hum",
      "name":"houma,  LA,  US"
   },
   {
      "id":"hou",
      "name":"houston,  TX,  US"
   },
   {
      "id":"hud",
      "name":"hudson valley,  NY,  US"
   },
   {
      "id":"hmb",
      "name":"humboldt,  CA,  US"
   },
   {
      "id":"hts",
      "name":"huntington,  WV,  US"
   },
   {
      "id":"hsv",
      "name":"huntsville,  AL,  US"
   },
   {
      "id":"hyd",
      "name":"hyderabad,  IN"
   },
   {
      "id":"ilo",
      "name":"iloilo,  PH"
   },
   {
      "id":"imp",
      "name":"imperial co,  CA,  US"
   },
   {
      "id":"ind",
      "name":"indianapolis,  IN,  US"
   },
   {
      "id":"jkt",
      "name":"indonesia,  ID"
   },
   {
      "id":"idr",
      "name":"indore,  IN"
   },
   {
      "id":"inl",
      "name":"inland empire,  CA,  US"
   },
   {
      "id":"iac",
      "name":"iowa city,  IA,  US"
   },
   {
      "id":"trh",
      "name":"iran,  IR"
   },
   {
      "id":"bgd",
      "name":"iraq,  IQ"
   },
   {
      "id":"ith",
      "name":"ithaca,  NY,  US"
   },
   {
      "id":"jxn",
      "name":"jackson, MI,  MI,  US"
   },
   {
      "id":"jan",
      "name":"jackson, MS,  MS,  US"
   },
   {
      "id":"jxt",
      "name":"jackson, TN,  TN,  US"
   },
   {
      "id":"jax",
      "name":"jacksonville, FL,  FL,  US"
   },
   {
      "id":"oaj",
      "name":"jacksonville, NC,  NC,  US"
   },
   {
      "id":"jai",
      "name":"jaipur,  IN"
   },
   {
      "id":"jvl",
      "name":"janesville,  WI,  US"
   },
   {
      "id":"jys",
      "name":"jersey shore,  NJ,  US"
   },
   {
      "id":"jrs",
      "name":"jerusalem,  IL"
   },
   {
      "id":"jnb",
      "name":"johannesburg,  ZA"
   },
   {
      "id":"jbr",
      "name":"jonesboro,  AR,  US"
   },
   {
      "id":"jln",
      "name":"joplin,  MO,  US"
   },
   {
      "id":"klt",
      "name":"kaiserslautern,  DE"
   },
   {
      "id":"kzo",
      "name":"kalamazoo,  MI,  US"
   },
   {
      "id":"fca",
      "name":"kalispell,  MT,  US"
   },
   {
      "id":"kml",
      "name":"kamloops,  BC,  CA"
   },
   {
      "id":"ksc",
      "name":"kansas city,  MO,  US"
   },
   {
      "id":"kel",
      "name":"kelowna,  BC,  CA"
   },
   {
      "id":"ena",
      "name":"kenai,  AK,  US"
   },
   {
      "id":"rac",
      "name":"kenosha-racine,  WI,  US"
   },
   {
      "id":"ken",
      "name":"kent,  GB"
   },
   {
      "id":"nbo",
      "name":"kenya,  KE"
   },
   {
      "id":"cok",
      "name":"kerala,  IN"
   },
   {
      "id":"grk",
      "name":"killeen-temple,  TX,  US"
   },
   {
      "id":"kng",
      "name":"kingston, ON,  ON,  CA"
   },
   {
      "id":"krk",
      "name":"kirksville,  MO,  US"
   },
   {
      "id":"kch",
      "name":"kitchener,  ON,  CA"
   },
   {
      "id":"klf",
      "name":"klamath falls,  OR,  US"
   },
   {
      "id":"knx",
      "name":"knoxville,  TN,  US"
   },
   {
      "id":"okk",
      "name":"kokomo,  IN,  US"
   },
   {
      "id":"kol",
      "name":"kolkata,  IN"
   },
   {
      "id":"koo",
      "name":"kootenays,  BC,  CA"
   },
   {
      "id":"kwi",
      "name":"kuwait,  KW"
   },
   {
      "id":"lse",
      "name":"la crosse,  WI,  US"
   },
   {
      "id":"lft",
      "name":"lafayette,  LA,  US"
   },
   {
      "id":"lkc",
      "name":"lake charles,  LA,  US"
   },
   {
      "id":"lcq",
      "name":"lake city,  FL,  US"
   },
   {
      "id":"lal",
      "name":"lakeland,  FL,  US"
   },
   {
      "id":"loz",
      "name":"lake of ozarks,  MO,  US"
   },
   {
      "id":"lns",
      "name":"lancaster, PA,  PA,  US"
   },
   {
      "id":"lan",
      "name":"lansing,  MI,  US"
   },
   {
      "id":"lrd",
      "name":"laredo,  TX,  US"
   },
   {
      "id":"lsl",
      "name":"la salle co,  IL,  US"
   },
   {
      "id":"lcr",
      "name":"las cruces,  NM,  US"
   },
   {
      "id":"lvg",
      "name":"las vegas,  NV,  US"
   },
   {
      "id":"lau",
      "name":"lausanne,  CH"
   },
   {
      "id":"lwr",
      "name":"lawrence,  KS,  US"
   },
   {
      "id":"law",
      "name":"lawton,  OK,  US"
   },
   {
      "id":"lds",
      "name":"leeds,  GB"
   },
   {
      "id":"lej",
      "name":"leipzig,  DE"
   },
   {
      "id":"lth",
      "name":"lethbridge,  AB,  CA"
   },
   {
      "id":"lws",
      "name":"lewiston,  ID,  US"
   },
   {
      "id":"lex",
      "name":"lexington,  KY,  US"
   },
   {
      "id":"lil",
      "name":"lille,  FR"
   },
   {
      "id":"lma",
      "name":"lima-findlay,  OH,  US"
   },
   {
      "id":"lnk",
      "name":"lincoln,  NE,  US"
   },
   {
      "id":"lis",
      "name":"lisbon,  PT"
   },
   {
      "id":"lit",
      "name":"little rock,  AR,  US"
   },
   {
      "id":"liv",
      "name":"liverpool,  GB"
   },
   {
      "id":"lgu",
      "name":"logan,  UT,  US"
   },
   {
      "id":"nte",
      "name":"loire valley,  FR"
   },
   {
      "id":"lon",
      "name":"london, ON,  ON,  CA"
   },
   {
      "id":"ldn",
      "name":"london, UK,  GB"
   },
   {
      "id":"isp",
      "name":"long island,  NY,  US"
   },
   {
      "id":"lax",
      "name":"los angeles,  CA,  US"
   },
   {
      "id":"lou",
      "name":"louisville,  KY,  US"
   },
   {
      "id":"lbb",
      "name":"lubbock,  TX,  US"
   },
   {
      "id":"lko",
      "name":"lucknow,  IN"
   },
   {
      "id":"lux",
      "name":"luxembourg,  LU"
   },
   {
      "id":"lyn",
      "name":"lynchburg,  VA,  US"
   },
   {
      "id":"lys",
      "name":"lyon,  FR"
   },
   {
      "id":"mcn",
      "name":"macon,  GA,  US"
   },
   {
      "id":"mad",
      "name":"madison,  WI,  US"
   },
   {
      "id":"mdd",
      "name":"madrid,  ES"
   },
   {
      "id":"mne",
      "name":"maine,  ME,  US"
   },
   {
      "id":"agp",
      "name":"malaga,  ES"
   },
   {
      "id":"mly",
      "name":"malaysia,  MY"
   },
   {
      "id":"man",
      "name":"manchester,  GB"
   },
   {
      "id":"mhk",
      "name":"manhattan,  KS,  US"
   },
   {
      "id":"mnl",
      "name":"manila,  PH"
   },
   {
      "id":"mkt",
      "name":"mankato,  MN,  US"
   },
   {
      "id":"mfd",
      "name":"mansfield,  OH,  US"
   },
   {
      "id":"mrs",
      "name":"marseille,  FR"
   },
   {
      "id":"msc",
      "name":"mason city,  IA,  US"
   },
   {
      "id":"mto",
      "name":"mattoon,  IL,  US"
   },
   {
      "id":"mzt",
      "name":"mazatlan,  MX"
   },
   {
      "id":"mca",
      "name":"mcallen,  TX,  US"
   },
   {
      "id":"mdv",
      "name":"meadville,  PA,  US"
   },
   {
      "id":"mfr",
      "name":"medford,  OR,  US"
   },
   {
      "id":"hat",
      "name":"medicine hat,  AB,  CA"
   },
   {
      "id":"mel",
      "name":"melbourne,  VIC,  AU"
   },
   {
      "id":"mem",
      "name":"memphis,  TN,  US"
   },
   {
      "id":"mdo",
      "name":"mendocino co,  CA,  US"
   },
   {
      "id":"mer",
      "name":"merced,  CA,  US"
   },
   {
      "id":"mei",
      "name":"meridian,  MS,  US"
   },
   {
      "id":"mex",
      "name":"mexico city,  MX"
   },
   {
      "id":"gum",
      "name":"micronesia,  GU"
   },
   {
      "id":"mxp",
      "name":"milan,  IT"
   },
   {
      "id":"mil",
      "name":"milwaukee,  WI,  US"
   },
   {
      "id":"min",
      "name":"minneapolis,  MN,  US"
   },
   {
      "id":"mso",
      "name":"missoula,  MT,  US"
   },
   {
      "id":"mob",
      "name":"mobile, AL,  AL,  US"
   },
   {
      "id":"mod",
      "name":"modesto,  CA,  US"
   },
   {
      "id":"mhv",
      "name":"mohave co,  AZ,  US"
   },
   {
      "id":"mlu",
      "name":"monroe, LA,  LA,  US"
   },
   {
      "id":"mnr",
      "name":"monroe, MI,  MI,  US"
   },
   {
      "id":"mtb",
      "name":"monterey,  CA,  US"
   },
   {
      "id":"mty",
      "name":"monterrey,  MX"
   },
   {
      "id":"mvd",
      "name":"montevideo,  UY"
   },
   {
      "id":"mgm",
      "name":"montgomery,  AL,  US"
   },
   {
      "id":"mpl",
      "name":"montpellier,  FR"
   },
   {
      "id":"mon",
      "name":"montreal,  QC,  CA"
   },
   {
      "id":"wvu",
      "name":"morgantown,  WV,  US"
   },
   {
      "id":"cas",
      "name":"morocco,  MA"
   },
   {
      "id":"mos",
      "name":"moscow,  RU"
   },
   {
      "id":"mlk",
      "name":"moses lake,  WA,  US"
   },
   {
      "id":"mum",
      "name":"mumbai,  IN"
   },
   {
      "id":"mun",
      "name":"muncie,  IN,  US"
   },
   {
      "id":"muc",
      "name":"munich,  DE"
   },
   {
      "id":"mkg",
      "name":"muskegon,  MI,  US"
   },
   {
      "id":"myr",
      "name":"myrtle beach,  SC,  US"
   },
   {
      "id":"ngo",
      "name":"nagoya,  JP"
   },
   {
      "id":"nmo",
      "name":"nanaimo,  BC,  CA"
   },
   {
      "id":"nkg",
      "name":"nanjing,  CN"
   },
   {
      "id":"nap",
      "name":"napoli,  IT"
   },
   {
      "id":"nsh",
      "name":"nashville,  TN,  US"
   },
   {
      "id":"nbw",
      "name":"new brunswick,  NB,  CA"
   },
   {
      "id":"ntl",
      "name":"newcastle, NSW,  NSW,  AU"
   },
   {
      "id":"ncl",
      "name":"newcastle, UK,  GB"
   },
   {
      "id":"nhm",
      "name":"new hampshire,  NH,  US"
   },
   {
      "id":"hvn",
      "name":"new haven,  CT,  US"
   },
   {
      "id":"nor",
      "name":"new orleans,  LA,  US"
   },
   {
      "id":"nyc",
      "name":"new york,  NY,  US"
   },
   {
      "id":"nsc",
      "name":"niagara region,  ON,  CA"
   },
   {
      "id":"mga",
      "name":"nicaragua,  NI"
   },
   {
      "id":"nfk",
      "name":"norfolk,  VA,  US"
   },
   {
      "id":"rou",
      "name":"normandy,  FR"
   },
   {
      "id":"ndk",
      "name":"north dakota,  ND,  US"
   },
   {
      "id":"abr",
      "name":"northeast SD,  SD,  US"
   },
   {
      "id":"nmi",
      "name":"northern MI,  MI,  US"
   },
   {
      "id":"nwi",
      "name":"northern WI,  WI,  US"
   },
   {
      "id":"whl",
      "name":"northern WV,  WV,  US"
   },
   {
      "id":"njy",
      "name":"north jersey,  NJ,  US"
   },
   {
      "id":"nms",
      "name":"north MS,  MS,  US"
   },
   {
      "id":"lbf",
      "name":"north platte,  NE,  US"
   },
   {
      "id":"nct",
      "name":"northwest CT,  CT,  US"
   },
   {
      "id":"nwg",
      "name":"northwest GA,  GA,  US"
   },
   {
      "id":"nwk",
      "name":"northwest KS,  KS,  US"
   },
   {
      "id":"end",
      "name":"northwest OK,  OK,  US"
   },
   {
      "id":"osl",
      "name":"norway,  NO"
   },
   {
      "id":"not",
      "name":"nottingham,  GB"
   },
   {
      "id":"nue",
      "name":"nuremberg,  DE"
   },
   {
      "id":"oax",
      "name":"oaxaca,  MX"
   },
   {
      "id":"oca",
      "name":"ocala,  FL,  US"
   },
   {
      "id":"odm",
      "name":"odessa,  TX,  US"
   },
   {
      "id":"ogd",
      "name":"ogden,  UT,  US"
   },
   {
      "id":"vps",
      "name":"okaloosa,  FL,  US"
   },
   {
      "id":"oka",
      "name":"okinawa,  JP"
   },
   {
      "id":"okc",
      "name":"oklahoma city,  OK,  US"
   },
   {
      "id":"olp",
      "name":"olympic pen,  WA,  US"
   },
   {
      "id":"oma",
      "name":"omaha,  NE,  US"
   },
   {
      "id":"onh",
      "name":"oneonta,  NY,  US"
   },
   {
      "id":"orc",
      "name":"orange co,  CA,  US"
   },
   {
      "id":"cor",
      "name":"oregon coast,  OR,  US"
   },
   {
      "id":"orl",
      "name":"orlando,  FL,  US"
   },
   {
      "id":"osa",
      "name":"osaka,  JP"
   },
   {
      "id":"ott",
      "name":"ottawa,  ON,  CA"
   },
   {
      "id":"obx",
      "name":"outer banks,  NC,  US"
   },
   {
      "id":"owb",
      "name":"owensboro,  KY,  US"
   },
   {
      "id":"ows",
      "name":"owen sound,  ON,  CA"
   },
   {
      "id":"oxf",
      "name":"oxford,  GB"
   },
   {
      "id":"pak",
      "name":"pakistan,  PK"
   },
   {
      "id":"psp",
      "name":"palm springs,  CA,  US"
   },
   {
      "id":"crk",
      "name":"pampanga,  PH"
   },
   {
      "id":"pan",
      "name":"panama,  PA"
   },
   {
      "id":"pfn",
      "name":"panama city, FL,  FL,  US"
   },
   {
      "id":"par",
      "name":"paris,  FR"
   },
   {
      "id":"pkb",
      "name":"parkersburg,  WV,  US"
   },
   {
      "id":"pax",
      "name":"peace country,  AB,  CA"
   },
   {
      "id":"pei",
      "name":"PEI,  PE,  CA"
   },
   {
      "id":"pns",
      "name":"pensacola,  FL,  US"
   },
   {
      "id":"pia",
      "name":"peoria,  IL,  US"
   },
   {
      "id":"per",
      "name":"perth,  WA,  AU"
   },
   {
      "id":"lim",
      "name":"peru,  PE"
   },
   {
      "id":"peg",
      "name":"perugia,  IT"
   },
   {
      "id":"ypq",
      "name":"peterborough,  ON,  CA"
   },
   {
      "id":"phi",
      "name":"philadelphia,  PA,  US"
   },
   {
      "id":"phx",
      "name":"phoenix,  AZ,  US"
   },
   {
      "id":"pit",
      "name":"pittsburgh,  PA,  US"
   },
   {
      "id":"plb",
      "name":"plattsburgh,  NY,  US"
   },
   {
      "id":"poc",
      "name":"poconos,  PA,  US"
   },
   {
      "id":"waw",
      "name":"poland,  PL"
   },
   {
      "id":"phn",
      "name":"port huron,  MI,  US"
   },
   {
      "id":"pdx",
      "name":"portland,  OR,  US"
   },
   {
      "id":"pto",
      "name":"porto,  PT"
   },
   {
      "id":"pgp",
      "name":"porto alegre,  BR"
   },
   {
      "id":"ptd",
      "name":"potsdam-massena,  NY,  US"
   },
   {
      "id":"prg",
      "name":"prague,  CZ"
   },
   {
      "id":"prc",
      "name":"prescott,  AZ,  US"
   },
   {
      "id":"hpr",
      "name":"pretoria,  ZA"
   },
   {
      "id":"yxs",
      "name":"prince george,  BC,  CA"
   },
   {
      "id":"pvu",
      "name":"provo,  UT,  US"
   },
   {
      "id":"pbl",
      "name":"puebla,  MX"
   },
   {
      "id":"pub",
      "name":"pueblo,  CO,  US"
   },
   {
      "id":"pri",
      "name":"puerto rico,  PR,  PR"
   },
   {
      "id":"pvr",
      "name":"puerto vallarta,  MX"
   },
   {
      "id":"plm",
      "name":"pullman-moscow,  WA,  US"
   },
   {
      "id":"pnq",
      "name":"pune,  IN"
   },
   {
      "id":"mli",
      "name":"quad cities,  IA,  US"
   },
   {
      "id":"qbc",
      "name":"quebec,  QC,  CA"
   },
   {
      "id":"ral",
      "name":"raleigh,  NC,  US"
   },
   {
      "id":"rap",
      "name":"rapid city,  SD,  US"
   },
   {
      "id":"rea",
      "name":"reading,  PA,  US"
   },
   {
      "id":"rec",
      "name":"recife,  BR"
   },
   {
      "id":"red",
      "name":"red deer,  AB,  CA"
   },
   {
      "id":"rdd",
      "name":"redding,  CA,  US"
   },
   {
      "id":"reg",
      "name":"regina,  SK,  CA"
   },
   {
      "id":"rno",
      "name":"reno,  NV,  US"
   },
   {
      "id":"rkv",
      "name":"reykjavik,  IS"
   },
   {
      "id":"prv",
      "name":"rhode island,  RI,  US"
   },
   {
      "id":"rin",
      "name":"richmond, IN,  IN,  US"
   },
   {
      "id":"ric",
      "name":"richmond, VA,  VA,  US"
   },
   {
      "id":"rio",
      "name":"rio de janeiro,  BR"
   },
   {
      "id":"roa",
      "name":"roanoke,  VA,  US"
   },
   {
      "id":"rmn",
      "name":"rochester, MN,  MN,  US"
   },
   {
      "id":"rcs",
      "name":"rochester, NY,  NY,  US"
   },
   {
      "id":"rfd",
      "name":"rockford,  IL,  US"
   },
   {
      "id":"buh",
      "name":"romania,  RO"
   },
   {
      "id":"rom",
      "name":"rome,  IT"
   },
   {
      "id":"rbg",
      "name":"roseburg,  OR,  US"
   },
   {
      "id":"row",
      "name":"roswell,  NM,  US"
   },
   {
      "id":"sac",
      "name":"sacramento,  CA,  US"
   },
   {
      "id":"mbs",
      "name":"saginaw,  MI,  US"
   },
   {
      "id":"sgy",
      "name":"saguenay,  QC,  CA"
   },
   {
      "id":"sle",
      "name":"salem,  OR,  US"
   },
   {
      "id":"sns",
      "name":"salina,  KS,  US"
   },
   {
      "id":"slc",
      "name":"salt lake,  UT,  US"
   },
   {
      "id":"ssa",
      "name":"salvador, bahia,  BR"
   },
   {
      "id":"sjt",
      "name":"san angelo,  TX,  US"
   },
   {
      "id":"sat",
      "name":"san antonio,  TX,  US"
   },
   {
      "id":"sdo",
      "name":"san diego,  CA,  US"
   },
   {
      "id":"sky",
      "name":"sandusky,  OH,  US"
   },
   {
      "id":"slo",
      "name":"san luis obispo,  CA,  US"
   },
   {
      "id":"tsu",
      "name":"san marcos,  TX,  US"
   },
   {
      "id":"sba",
      "name":"santa barbara,  CA,  US"
   },
   {
      "id":"saf",
      "name":"santa fe,  NM,  US"
   },
   {
      "id":"smx",
      "name":"santa maria,  CA,  US"
   },
   {
      "id":"spo",
      "name":"sao paulo,  BR"
   },
   {
      "id":"spp",
      "name":"sapporo,  JP"
   },
   {
      "id":"srq",
      "name":"sarasota,  FL,  US"
   },
   {
      "id":"srd",
      "name":"sardinia,  IT"
   },
   {
      "id":"srn",
      "name":"sarnia,  ON,  CA"
   },
   {
      "id":"skt",
      "name":"saskatoon,  SK,  CA"
   },
   {
      "id":"soo",
      "name":"sault ste marie, ON,  ON,  CA"
   },
   {
      "id":"sav",
      "name":"savannah,  GA,  US"
   },
   {
      "id":"bff",
      "name":"scottsbluff,  NE,  US"
   },
   {
      "id":"avp",
      "name":"scranton,  PA,  US"
   },
   {
      "id":"sea",
      "name":"seattle,  WA,  US"
   },
   {
      "id":"sdj",
      "name":"sendai,  JP"
   },
   {
      "id":"sel",
      "name":"seoul,  KR"
   },
   {
      "id":"sev",
      "name":"sevilla,  ES"
   },
   {
      "id":"sfo",
      "name":"SF bay area,  CA,  US"
   },
   {
      "id":"sha",
      "name":"shanghai,  CN"
   },
   {
      "id":"sbm",
      "name":"sheboygan, WI,  WI,  US"
   },
   {
      "id":"shf",
      "name":"sheffield,  GB"
   },
   {
      "id":"she",
      "name":"shenyang,  CN"
   },
   {
      "id":"szx",
      "name":"shenzhen,  CN"
   },
   {
      "id":"shb",
      "name":"sherbrooke,  QC,  CA"
   },
   {
      "id":"sow",
      "name":"show low,  AZ,  US"
   },
   {
      "id":"shv",
      "name":"shreveport,  LA,  US"
   },
   {
      "id":"sic",
      "name":"sicilia,  IT"
   },
   {
      "id":"fhu",
      "name":"sierra vista,  AZ,  US"
   },
   {
      "id":"sng",
      "name":"singapore,  SG"
   },
   {
      "id":"sux",
      "name":"sioux city,  IA,  US"
   },
   {
      "id":"fsd",
      "name":"sioux falls,  SD,  US"
   },
   {
      "id":"ssk",
      "name":"siskiyou co,  CA,  US"
   },
   {
      "id":"mvw",
      "name":"skagit,  WA,  US"
   },
   {
      "id":"ske",
      "name":"skeena-bulkley,  BC,  CA"
   },
   {
      "id":"sbn",
      "name":"south bend,  IN,  US"
   },
   {
      "id":"sma",
      "name":"south coast,  MA,  US"
   },
   {
      "id":"sdk",
      "name":"south dakota,  SD,  US"
   },
   {
      "id":"jnu",
      "name":"southeast AK,  AK,  US"
   },
   {
      "id":"otu",
      "name":"southeast IA,  IA,  US"
   },
   {
      "id":"sek",
      "name":"southeast KS,  KS,  US"
   },
   {
      "id":"smo",
      "name":"southeast MO,  MO,  US"
   },
   {
      "id":"cbd",
      "name":"southern IL,  IL,  US"
   },
   {
      "id":"smd",
      "name":"southern MD,  MD,  US"
   },
   {
      "id":"swv",
      "name":"southern WV,  WV,  US"
   },
   {
      "id":"mia",
      "name":"south florida,  FL,  US"
   },
   {
      "id":"snj",
      "name":"south jersey,  NJ,  US"
   },
   {
      "id":"swk",
      "name":"southwest KS,  KS,  US"
   },
   {
      "id":"swm",
      "name":"southwest MI,  MI,  US"
   },
   {
      "id":"mml",
      "name":"southwest MN,  MN,  US"
   },
   {
      "id":"hez",
      "name":"southwest MS,  MS,  US"
   },
   {
      "id":"wtx",
      "name":"southwest TX,  TX,  US"
   },
   {
      "id":"vaw",
      "name":"southwest VA,  VA,  US"
   },
   {
      "id":"mlb",
      "name":"space coast,  FL,  US"
   },
   {
      "id":"spk",
      "name":"spokane,  WA,  US"
   },
   {
      "id":"sgf",
      "name":"springfield,  MO,  US"
   },
   {
      "id":"spi",
      "name":"springfield, IL,  IL,  US"
   },
   {
      "id":"psu",
      "name":"state college,  PA,  US"
   },
   {
      "id":"tbr",
      "name":"statesboro,  GA,  US"
   },
   {
      "id":"ust",
      "name":"st augustine,  FL,  US"
   },
   {
      "id":"stc",
      "name":"st cloud,  MN,  US"
   },
   {
      "id":"stg",
      "name":"st george,  UT,  US"
   },
   {
      "id":"osu",
      "name":"stillwater,  OK,  US"
   },
   {
      "id":"nfl",
      "name":"st john's, NL,  NL,  CA"
   },
   {
      "id":"stj",
      "name":"st joseph,  MO,  US"
   },
   {
      "id":"stl",
      "name":"st louis,  MO,  US"
   },
   {
      "id":"stk",
      "name":"stockton,  CA,  US"
   },
   {
      "id":"stp",
      "name":"st petersburg,  RU"
   },
   {
      "id":"sxb",
      "name":"strasbourg,  FR"
   },
   {
      "id":"str",
      "name":"stuttgart,  DE"
   },
   {
      "id":"sud",
      "name":"sudbury,  ON,  CA"
   },
   {
      "id":"sun",
      "name":"sunshine coast,  BC,  CA"
   },
   {
      "id":"svt",
      "name":"surat surat,  IN"
   },
   {
      "id":"ssn",
      "name":"susanville,  CA,  US"
   },
   {
      "id":"sth",
      "name":"sweden,  SE"
   },
   {
      "id":"syd",
      "name":"sydney,  NSW,  AU"
   },
   {
      "id":"syr",
      "name":"syracuse,  NY,  US"
   },
   {
      "id":"twd",
      "name":"taiwan,  TW"
   },
   {
      "id":"tal",
      "name":"tallahassee,  FL,  US"
   },
   {
      "id":"tpa",
      "name":"tampa bay,  FL,  US"
   },
   {
      "id":"hba",
      "name":"tasmania,  TAS,  AU"
   },
   {
      "id":"tlv",
      "name":"tel aviv,  IL"
   },
   {
      "id":"tha",
      "name":"terre haute,  IN,  US"
   },
   {
      "id":"toc",
      "name":"territories,  NT,  CA"
   },
   {
      "id":"txk",
      "name":"texarkana,  AR,  US"
   },
   {
      "id":"txm",
      "name":"texoma,  TX,  US"
   },
   {
      "id":"bkk",
      "name":"thailand,  TH"
   },
   {
      "id":"msl",
      "name":"the shoals,  AL,  US"
   },
   {
      "id":"thb",
      "name":"the thumb,  MI,  US"
   },
   {
      "id":"tby",
      "name":"thunder bay,  ON,  CA"
   },
   {
      "id":"tij",
      "name":"tijuana,  MX"
   },
   {
      "id":"laf",
      "name":"tippecanoe,  IN,  US"
   },
   {
      "id":"tok",
      "name":"tokyo,  JP"
   },
   {
      "id":"tol",
      "name":"toledo,  OH,  US"
   },
   {
      "id":"tpk",
      "name":"topeka,  KS,  US"
   },
   {
      "id":"trn",
      "name":"torino,  IT"
   },
   {
      "id":"tor",
      "name":"toronto,  ON,  CA"
   },
   {
      "id":"tls",
      "name":"toulouse,  FR"
   },
   {
      "id":"psl",
      "name":"treasure coast,  FL,  US"
   },
   {
      "id":"tri",
      "name":"tri-cities, TN,  TN,  US"
   },
   {
      "id":"kpr",
      "name":"tri-cities, WA,  WA,  US"
   },
   {
      "id":"trs",
      "name":"trois-rivieres,  QC,  CA"
   },
   {
      "id":"tus",
      "name":"tucson,  AZ,  US"
   },
   {
      "id":"tul",
      "name":"tulsa,  OK,  US"
   },
   {
      "id":"tun",
      "name":"tunisia,  TN"
   },
   {
      "id":"ist",
      "name":"turkey,  TR"
   },
   {
      "id":"tsc",
      "name":"tuscaloosa,  AL,  US"
   },
   {
      "id":"nph",
      "name":"tuscarawas co,  OH,  US"
   },
   {
      "id":"twf",
      "name":"twin falls,  ID,  US"
   },
   {
      "id":"tts",
      "name":"twin tiers,  NY,  US"
   },
   {
      "id":"kbp",
      "name":"ukraine,  UA"
   },
   {
      "id":"uti",
      "name":"utica,  NY,  US"
   },
   {
      "id":"vld",
      "name":"valdosta,  GA,  US"
   },
   {
      "id":"val",
      "name":"valencia,  ES"
   },
   {
      "id":"van",
      "name":"vancouver, BC,  BC,  CA"
   },
   {
      "id":"ccs",
      "name":"venezuela,  VE"
   },
   {
      "id":"vce",
      "name":"venice,  IT"
   },
   {
      "id":"oxr",
      "name":"ventura,  CA,  US"
   },
   {
      "id":"vcz",
      "name":"veracruz,  MX"
   },
   {
      "id":"brl",
      "name":"vermont,  VT,  US"
   },
   {
      "id":"vic",
      "name":"victoria, BC,  BC,  CA"
   },
   {
      "id":"vtx",
      "name":"victoria, TX,  TX,  US"
   },
   {
      "id":"vie",
      "name":"vienna,  AT"
   },
   {
      "id":"vtn",
      "name":"vietnam,  VN"
   },
   {
      "id":"vrg",
      "name":"virgin islands,  VI"
   },
   {
      "id":"vis",
      "name":"visalia-tulare,  CA,  US"
   },
   {
      "id":"wco",
      "name":"waco,  TX,  US"
   },
   {
      "id":"wdc",
      "name":"washington, DC,  DC,  US"
   },
   {
      "id":"wlo",
      "name":"waterloo,  IA,  US"
   },
   {
      "id":"wtn",
      "name":"watertown,  NY,  US"
   },
   {
      "id":"wau",
      "name":"wausau,  WI,  US"
   },
   {
      "id":"wll",
      "name":"wellington,  NZ"
   },
   {
      "id":"wen",
      "name":"wenatchee,  WA,  US"
   },
   {
      "id":"pal",
      "name":"west bank,  PS"
   },
   {
      "id":"qcy",
      "name":"western IL,  IL,  US"
   },
   {
      "id":"wky",
      "name":"western KY,  KY,  US"
   },
   {
      "id":"wma",
      "name":"western mass,  MA,  US"
   },
   {
      "id":"wmd",
      "name":"western MD,  MD,  US"
   },
   {
      "id":"gjt",
      "name":"western slope,  CO,  US"
   },
   {
      "id":"wva",
      "name":"west virginia,  WV,  US"
   },
   {
      "id":"whi",
      "name":"whistler / squamish,  BC,  CA"
   },
   {
      "id":"whh",
      "name":"whitehorse,  YK,  CA"
   },
   {
      "id":"wic",
      "name":"wichita,  KS,  US"
   },
   {
      "id":"wtf",
      "name":"wichita falls,  TX,  US"
   },
   {
      "id":"wpt",
      "name":"williamsport,  PA,  US"
   },
   {
      "id":"wnc",
      "name":"wilmington, NC,  NC,  US"
   },
   {
      "id":"okv",
      "name":"winchester,  VA,  US"
   },
   {
      "id":"wsr",
      "name":"windsor,  ON,  CA"
   },
   {
      "id":"win",
      "name":"winnipeg,  MB,  CA"
   },
   {
      "id":"wsl",
      "name":"winston-salem,  NC,  US"
   },
   {
      "id":"wol",
      "name":"wollongong,  NSW,  AU"
   },
   {
      "id":"wor",
      "name":"worcester,  MA,  US"
   },
   {
      "id":"wuh",
      "name":"wuhan,  CN"
   },
   {
      "id":"wyo",
      "name":"wyoming,  WY,  US"
   },
   {
      "id":"xiy",
      "name":"xi'an,  CN"
   },
   {
      "id":"yak",
      "name":"yakima,  WA,  US"
   },
   {
      "id":"ykf",
      "name":"yellowknife,  NT,  CA"
   },
   {
      "id":"yup",
      "name":"yoopers,  MI,  US"
   },
   {
      "id":"yrk",
      "name":"york, PA,  PA,  US"
   },
   {
      "id":"yng",
      "name":"youngstown,  OH,  US"
   },
   {
      "id":"ybs",
      "name":"yuba-sutter,  CA,  US"
   },
   {
      "id":"yuc",
      "name":"yucatan,  MX"
   },
   {
      "id":"yum",
      "name":"yuma,  AZ,  US"
   },
   {
      "id":"zam",
      "name":"zamboanga,  PH"
   },
   {
      "id":"zvl",
      "name":"zanesville,  OH,  US"
   },
   {
      "id":"zur",
      "name":"zurich,  CH"
   }
]
