import React from "react";
import {
  List,
  Datagrid,
  DateField,
  TextField,
  NumberField,
  RichTextField,
  ReferenceField,
  SelectField,
} from "react-admin";
import { withStyles } from '@material-ui/core/styles';
import { BooleanNumField } from "./booleanNumField";
import { CustomImageField } from "./customImageField";
import {
  postingCityChoices,
  offerupCategoryChoices,
  categoryChoices,
  mobileOsChoices,
} from "./selectChoices";

const styles = {
  image: { maxHeight: 50, maxWidth: 50, display: 'flex' }
}
export const PostLogList = withStyles(styles)((props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid actions="">
      <TextField source="id" />
      <ReferenceField source="post_id" reference="posts">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="title" />
      <NumberField source="price" label="Price($)" />
      <RichTextField source="body" />
      <NumberField source="postalCode" />
      <CustomImageField source="image_path" label="Image" classes={props.classes} />

      <SelectField source="postingCity" choices={postingCityChoices} />
      <SelectField source="category" choices={categoryChoices} />
      <SelectField source="offerupCategory" choices={offerupCategoryChoices} />
      <SelectField source="mobile_os" choices={mobileOsChoices} />

      <TextField source="type" />
      <BooleanNumField source="isSuccess" />
      <DateField source="updatedAt" showTime={true} />
      <DateField source="createdAt" showTime={true} />
    </Datagrid>
  </List>
));
