import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Utils } from "./Utils";
import { Constants } from "./Constants";
import { useAuthenticated } from "react-admin";
import Axios from "axios";
import { SimpleForm, PasswordInput, useNotify, useRedirect } from "react-admin";

const UpdatePassword = () => {
  useAuthenticated();
  const notify = useNotify();
  const redirect = useRedirect();
  const [state, setState] = React.useState({
    currentPass: '', newPass: '', confNewPass: '',
    currentPass_error: false, newPass_error: false, confNewPass_error: false,
    currentPass_error_text: '', newPass_error_text: '', confNewPass_error_text: ''
  });
  const handleOnChange = event => {
    const { name, value } = event.target;
    const errors = name + "_error";
    const errors_text = name + "_error_text";
    setState({
      ...state, [name]: value,
      [errors]: false,
      [errors_text]: ""
    });
  };
  const validate = () => {
    let errorValue = false;
    if (state.currentPass === '' || state.newPass === '' || state.confNewPass === '') {
      notify('Input fields cannot be empty.', 'warning');
      errorValue = true;
    }
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (passwordRegex.test(state.newPass)) {
      setState(prevState => ({
        ...prevState, newPass_error: false,
        newPass_error_text: ""
      }));

    } else {
      setState(prevState => ({
        ...prevState, newPass_error: true,
        newPass_error_text: state.newPass ? 'Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character' : 'Required'
      }));
      errorValue = true;
    }

    if (state.newPass !== state.confNewPass) {
      setState(prevState => ({
        ...prevState, confNewPass_error: true,
        confNewPass_error_text: state.confNewPass ? "Password does not match" : "Required"
      }));
      errorValue = true;

    } else {
      setState(prevState => ({
        ...prevState, confNewPass_error: false,
        confNewPass_error_text: ""
      }));
    }

    if (!(state.currentPass)) {
      setState(prevState => ({
        ...prevState, currentPass_error: true,
        currentPass_error_text: 'Required'
      }));
      errorValue = true;

    }
    if (!(state.newPass)) {
      setState(prevState => ({
        ...prevState, newPass_error: true,
        newPass_error_text: 'Required'
      }));
      errorValue = true;

    }
    if (!(state.confNewPass)) {
      setState(prevState => ({
        ...prevState, confNewPass_error: true,
        confNewPass_error_text: 'Required'
      }));
      errorValue = true;

    }
    if (errorValue === true) {
      return false;
    }
    else {
      return true;
    }
  }

  function handleSubmit() {

    if (!validate()) {

      return;
    } else {
      let reqBody = JSON.stringify({
        old_password: state.currentPass,
        new_password: state.newPass, //ADD this line
      });
      return Axios.put(Constants.BASE_URL + "users/password", reqBody, {
        headers: Constants.REQUEST_HEADER_JSON,
      })
        .then((response) => {
          console.log(response);
          notify('Password changed successfully');
          redirect('/');
        })
        .catch((error) => {
          notify('Invalid Form', 'warning');
        });
    }
  }
  if (Utils.isLoggedIn() === false) {
    return <div></div>;
  }
  return (
    <Card>
      <CardHeader title="Update Password" />
      <CardContent>
        <div>
          <SimpleForm save={handleSubmit}>
            <PasswordInput label="Current password" name="currentPass" error={state.currentPass_error} helperText={state.currentPass_error_text} onChange={handleOnChange} value={state.currentPass} source="old_password" />
            <PasswordInput label="New password" name="newPass" error={state.newPass_error} helperText={state.newPass_error_text} onChange={handleOnChange} value={state.newPass} source="new_password" />
            <PasswordInput label="Confirm password" name="confNewPass" error={state.confNewPass_error} helperText={state.confNewPass_error_text} onChange={handleOnChange} value={state.confNewPass} source="re_new_password" />
            <div id="response">{state.response}</div>
          </SimpleForm>
        </div>
      </CardContent>
    </Card>
  );
};

export default UpdatePassword;
