import React from "react";
import { Route } from "react-router-dom";
import { Home } from "./home";
import { HowToBot } from "./howToBot";
import Dashboard from "./Dashboard";
import UpdatePassword from "./UpdatePassword";
import ResetPasswordUpdate from "./ResetPasswordUpdate";
import ResetPassword from "./ResetPassword";
import EditProfile from "./EditProfile";

export default [
  <Route exact path="/" noLayout>
    {" "}
    <Home />{" "}
  </Route>,
   <Route exact path="/how-to-bot" noLayout>
   {" "}
   <HowToBot />{" "}
 </Route>,
  <Route exact path="/dashboard">
    {" "}
    <Dashboard />{" "}
  </Route>,
  <Route exact path="/update_password">
    {" "}
    <UpdatePassword />{" "}
  </Route>,
  <Route exact path="/edit_profile">
  {" "}
  <EditProfile />{" "}
</Route>,
  <Route exact path="/reset_password" noLayout>
    {" "}
    <ResetPassword />{" "}
  </Route>,
  <Route exact path="/reset_password_update" noLayout>
    {" "}
    <ResetPasswordUpdate />{" "}
  </Route>,
];
