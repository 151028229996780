import jsonServerProvider from "ra-data-json-server";
import axios from "axios";
import { fetchUtils } from "react-admin";
import { Constants } from "./Constants";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("x-access-token", `${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(Constants.BASE_URL_2, httpClient);
const myDataProvider = {
  ...dataProvider,
  getOne: (resource, params) => {
    return httpClient(Constants.BASE_URL_2 + "/" + resource + "/" + params.id).then(function (_a) {
      var json = _a.json;
      return ({
        data: json[0],
      });
    });
  },
  create: (resource, params) => {
    if (resource !== "posts" || !params.data.pictures) {
      return dataProvider.create(resource, params);
    }
    const listCalls = []
    for (let i = 0; i < params.data.pictures.length; i++) {
      listCalls.push(uploadFile(params.data.pictures[i].rawFile))
    }
    let imagesPath = ""
    return Promise.all(listCalls)
      .then((values) => {
        for (let i = 0; i < values.length; i++) {
          imagesPath = imagesPath + (imagesPath ? (',' + values[i]) : (values[i]))
        }
        params.data.image_path = imagesPath
        return dataProvider.create(resource, params);
      })
  },
  update: (resource, params) => {
    if (resource !== "posts" || !params.data.pictures) {
      return dataProvider.update(resource, params).then(() => ({ data: params.data }));
    }
    // params.data.image_path = ""
    const listCalls = []
    for (let i = 0; i < params.data.pictures.length; i++) {
      listCalls.push(uploadFile(params.data.pictures[i].rawFile))
    }
    let imagesPath = ""
    return Promise.all(listCalls)
      .then((values) => {
        for (let i = 0; i < values.length; i++) {
          imagesPath = imagesPath + (imagesPath ? (',' + values[i]) : (values[i]))
        }
        params.data.image_path = params.data.image_path ? params.data.image_path + ',' + imagesPath : imagesPath
        return dataProvider.update(resource, params).then(() => ({ data: params.data }));
      })
    // return new Promise((resolve, reject) => {
    //   let imagesPath = ""
    //   for (let i = 0; i < params.data.pictures.length; i++) {
    //     uploadFile(params.data.pictures[i].rawFile)
    //       .then((res) => {
    //         imagesPath = imagesPath + (imagesPath ? (',' + res) : (res))
    //         const newData = {
    //           ...params,
    //           data: {
    //             ...params.data,
    //             image_path: imagesPath,
    //           },
    //         }
    //         dataProvider
    //           .update(resource, newData)
    //           .then(resolve)
    //           .catch(reject);
    //       })
    //       .catch(reject);
    //   }
    // });
  },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const uploadFile = (file) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(
        Constants.POST_IMAGES_API_PATH +
        JSON.parse(localStorage.getItem("user")).id,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => resolve(res.data.fileUrl))
      .catch((error) => {
        console.error("File upload Failed");
        console.error(error);
        reject("File upload failed");
      });
  });

export default myDataProvider;

// return new Promise((resolve, reject) => {
//   let imagesPath = ""
//   return dataProvider.create(resource, params)
//     .then(response => {
//       params = response
//       for (let i = 0; i < params.data.pictures.length; i++) {
//         uploadFile(params.data.pictures[i].rawFile)
//           .then((res) => {
//             imagesPath = imagesPath + (imagesPath ? (',' + res) : (res))
//             dataProvider
//               .update(resource, {
//                 data: {
//                   ...params.data,
//                   image_path: imagesPath,
//                 },
//               })
//               .then(resolve)
//               .catch(reject);
//           })
//           .catch(reject);
//       }
//     })
//     .catch(reject);

// });