import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Utils } from "./Utils";
import { Constants } from "./Constants";
import Axios from "axios";
import { Logout } from "react-admin";
import { Link } from "@material-ui/core";

import { SimpleForm, PasswordInput } from "react-admin";

function handleSubmit() {
  let new_password = document.getElementById("new_password").value;
  let re_new_password = document.getElementById("re_new_password").value;
  let token = getToken();
  document.getElementById("response").innerText = "";
  if (token === undefined) {
    document.getElementById("response").innerText = "Invalid Parameter";
    return;
  }
  if (new_password !== re_new_password) {
    document.getElementById("response").innerText = "Confirm password is wrong";
    return;
  }

  let reqBody = JSON.stringify({
    token: token,
    new_password: new_password, //ADD this line
  });
  return Axios.put(Constants.BASE_URL + "users/resetPasswordUpdate", reqBody, {
    headers: Constants.REQUEST_HEADER_JSON,
  })
    .then((response) => {
      document.getElementById("response").innerText = "Success";
    })
    .catch((error) => {
      document.getElementById("response").innerText = "Failed";
    });
}

const UserEdit = (props) => (
  <div>
    <SimpleForm save={handleSubmit}>
      <PasswordInput autoFocus label="New password" source="new_password" />
      <PasswordInput label="Confirm password" source="re_new_password" />
    </SimpleForm>
    <div id="response" />
    <Link href="/">Home</Link>
  </div>
);
const ResetPasswordUpdate = (props) => {
  if (Utils.isLoggedIn() === true) {
    return displayLogout();
  }

  if (getToken() === undefined) {
    return displayTokenMissing();
  }

  return (
    <Card>
      <CardHeader title="Reset your password" />
      <CardContent>
        <UserEdit />
      </CardContent>
    </Card>
  );
};
function getToken() {
  return Utils.getqueryParam("token");
}
function displayTokenMissing() {
  return (
    <Card>
      <CardHeader title="Invalid Parameter" />
      <CardContent>
        <a href="/"> Home</a>
      </CardContent>
    </Card>
  );
}

function displayLogout() {
  return (
    <Card>
      <CardHeader title="You are logged in. Logout and retry to reset password" />
      <CardContent>
        <Logout />
      </CardContent>
    </Card>
  );
}

export default ResetPasswordUpdate;
