import React from "react";
import { Fragment } from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  NumberField,
  RichTextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  ImageInput,
  ImageField,
  SelectField,
  useNotify,
  useRedirect,
  useRefresh,
  Toolbar,
  SaveButton,
  DeleteButton

} from "react-admin";
import {  Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { BooleanNumField } from "./booleanNumField";
import { CustomImageField } from "./customImageField";
import  CustomBulkDeleteAction  from './CustomBulkDeleteAction';
import {
  postingCityChoices,
  offerupCategoryChoices,
  categoryChoices,
  mobileOsChoices,
} from "./selectChoices";
import { Field } from 'react-final-form'
const choices = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" },
];

const styles = {
  image: { maxHeight: 100 }

};

const validationRules = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = ['Required'];
  }
  if (!values.price) {
    errors.price = ['Required'];
  } else if (isNaN(values.price)) {
    errors.price = ['Price must be a number'];
  }
  if (!values.body) {
    errors.body = ['Required'];
  }
  if (!values.postalCode) {
    errors.postalCode = ['Required'];
  } else if (isNaN(values.postalCode)) {
    errors.postalCode = ['Postal Code must be a number'];
  } else if (values.postalCode.length > 7) {
    errors.postalCode = ['Input length must not exceed the required limit of 7.'];
  }
  if (!values.postingCity) {
    errors.postingCity = ['Required'];
  }
  if (!values.category) {
    errors.category = ['Required'];
  }
  if (!values.offerupCategory) {
    errors.offerupCategory = ['Required'];
  }
  if (values.category === '153' && !values.mobile_os) {
    errors.mobile_os = ['Required'];
  }
  if (!values.image_path) {
    if (!values.pictures) {
      errors.pictures = ['Required']
    } else if (values.pictures.length < 3) {
      errors.pictures = ['Upload atleast three images']
    } else if (values.pictures.length > 6) {
      errors.pictures = ['You can not upload more than six images'];
    }
  } else {
    const fileCount = (values.image_path.split(',') || []).length + (values.pictures || []).length
    if (fileCount < 3) {
      errors.pictures = ['Upload atleast three images']
    } else if (fileCount > 6) {
      errors.pictures = ['You can not upload more than six images'];
    }
  }
  return errors
};

const PostBulkActionButtons = props => (
  <Fragment>
    <CustomBulkDeleteAction label="Delete" {...props} />
    
    {/* <BulkDeleteButton undoable={false} {...props} /> */}
  
  </Fragment>
);
const listImageStyle = {
  height: '50px',
  marginRight: '10px'
}
export const PostList = withStyles(styles)((props) => (
  <List {...props} bulkActionButtons={<PostBulkActionButtons />} exporter={false}>
    <Datagrid rowClick="edit" key="id">
      <TextField source="id" />
      <TextField source="title" />
      <NumberField source="price" label="Price($)" />
      <RichTextField source="body" />
      <NumberField source="postalCode" />
      <CustomImageField source="image_path" label="Image" style={listImageStyle} />
      <SelectField source="postingCity" choices={postingCityChoices} />
      <SelectField source="category" choices={categoryChoices} />
      <SelectField source="offerupCategory" choices={offerupCategoryChoices} />
      <SelectField source="mobile_os" choices={mobileOsChoices} />
      <DateField source="lastPostedLetgo" showTime={true} />
      <DateField source="lastPostedCraiglist" showTime={true} />
      <DateField source="lastPostedOfferup" showTime={true} />
      <BooleanNumField source="isLetgo" />
      <BooleanNumField source="isLetgoHold" />
      <BooleanNumField source="isOfferup" />
      <BooleanNumField source="isOfferupHold" />
      <BooleanNumField source="isCraiglist" />
      <BooleanNumField source="isCraiglistHold" />
      <DateField source="updatedAt" showTime={true} />
      <DateField source="createdAt" showTime={true} />
    </Datagrid>
  </List>
));

const editFormImageStyle = {
  height: "100px",
  marginRight: '10px'
}
const EditImageComponent = (props) => <Field name="image_path" component={CustomImageField} {...props} />


const toolbarStyle = {
  "display":"flex",
  "justify-content":"space-between"
}
const PostEditToolbar = (props) => {
  
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccessDelete = () => {
    notify('Post deleted successfully'); // default message is 'ra.notification.updated'
    redirect('/posts');
  }

  return(
  <Toolbar style={toolbarStyle} {...props} >
    
      <SaveButton   disabled={props.pristine} />
      <DeleteButton  confirmContent="Are you sure you want to delete this post?"
      onSuccess={onSuccessDelete}
      undoable={false}/>
    </Toolbar>
  
)};



export const PostEdit = withStyles(styles)((props) =>
{
 
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccessEdit = ({ data }) => {
    notify('Post updated successfully'); // default message is 'ra.notification.updated'
    refresh();
  }
  return(
  <Edit  onSuccess={onSuccessEdit} undoable={false}  {...props}>
    <SimpleForm  toolbar={<PostEditToolbar />} validate={validationRules}>
      <TextInput disabled source="id" />
      <TextInput source="title" />
      <NumberInput source="price" />
      <TextInput multiline rows={6} style={{ width: "60%" }} source="body" />
      <NumberInput source="postalCode" />
      <SelectInput source="postingCity" choices={postingCityChoices} />
      <SelectInput source="category" choices={categoryChoices} />
      <SelectInput source="offerupCategory" choices={offerupCategoryChoices} />
      <SelectInput source="mobile_os" choices={mobileOsChoices} />
      <EditImageComponent source="image_path" label="Image" edit={true} style={editFormImageStyle} classes={props.classes} title="Attached images" />
      <ImageInput multiple source="pictures" label="Attach More Images" accept="image/*" placeholder={<p>Drop More images here</p>}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <SelectInput label="Stop posting on Letgo"source="isLetgoHold" choices={choices} />
      <SelectInput label="Stop posting on Craiglist"source="isCraiglistHold" choices={choices} />
      <SelectInput label="Stop posting on Offerup"source="isOfferupHold" choices={choices} />
    </SimpleForm>
  </Edit>
)
});

export const PostCreate = withStyles(styles)((props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccessCreate = ({ data }) => {
    notify('Post created successfully'); // default message is 'ra.notification.updated'
    redirect('edit', props.basePath, data.id, data);
  }
  return (
    <Create  {...props} onSuccess={onSuccessCreate} >
      <SimpleForm validate={validationRules} >
        <TextInput label="Title" source="title" />
        <NumberInput source="price" />
        <TextInput multiline rows={6} style={{ width: "60%" }} source="body" />
        <NumberInput source="postalCode" />
        <SelectInput
          source="postingCity"
          choices={postingCityChoices}
          defaultValue="orl"
        />
        <SelectInput
          source="category"
          choices={categoryChoices}
          defaultValue="153"
        />
        <SelectInput source="offerupCategory" choices={offerupCategoryChoices} />
        <SelectInput source="mobile_os" choices={mobileOsChoices} />
        <ImageInput multiple source="pictures" label="Attach Images" accept="image/*" placeholder={<p>Drop your images here</p>}>
          <ImageField source="src" title="title" classes={props.classes} />
        </ImageInput>

        <SelectInput label="Allow posting on Letgo" source="isLetgo" choices={choices} defaultValue="1" />
        <SelectInput label="Allow posting on Craiglist" source="isCraiglist" choices={choices} defaultValue="1" />
        <SelectInput label="Allow posting on Offerup" source="isOfferup" choices={choices} defaultValue="1" />
      </SimpleForm>
    </Create>
  )
});
