import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Utils } from './Utils';
import { Constants } from './Constants';
import Axios from 'axios';
import { Logout } from 'react-admin';
import { Link } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { SimpleForm } from 'react-admin';

function handleSubmit() {
  let email = document.getElementById('email').value;
  document.getElementById('response').innerText = '';

  let reqBody = JSON.stringify({ email: email });
  return Axios.put(Constants.BASE_URL + 'users/resetPassword', reqBody, {
    headers: Constants.REQUEST_HEADER_JSON,
  })
    .then((response) => {
      document.getElementById('response').innerText = 'Success';
    })
    .catch((error) => {
      document.getElementById('response').innerText = 'Failed';
    });
}

const UserEdit = (props) => (
  <div>
    An email containing the reset information will be sent if user is registered
    <SimpleForm save={handleSubmit}>
      <TextField
        required
        autoFocus
        margin="dense"
        id="email"
        label="Email Address"
        type="email"
      />
    </SimpleForm>
    <div id="response" />
    <Link href="/">Home</Link>
  </div>
);

const ResetPassword = (props) => {
  if (Utils.isLoggedIn() === true) {
    return displayLogout();
  }
  return (
    <Card>
      <CardHeader title="Reset your password" />
      <CardContent>
        <UserEdit />
      </CardContent>
    </Card>
  );
};

export function getToken() {
  return Utils.getqueryParam('token');
}

export function displayTokenMissing() {
  return (
    <Card>
      <CardHeader title="Invalid Parameter" />
      <CardContent>
        <a href="/"> Home</a>
      </CardContent>
    </Card>
  );
}

function displayLogout() {
  return (
    <Card>
      <CardHeader title="You are logged in. Logout and retry to reset password" />
      <CardContent>
        <Logout />
      </CardContent>
    </Card>
  );
}

export default ResetPassword;
