import React from "react";
import { Constants } from "./Constants";
export const CustomImageField = ({ style, edit, record = {}, source, title, input = {} }) => {  
  const {
    value,
    onChange
  } = input
  let files = (value || record[source] || '').split(',')
  const handleRemoveImages = (f) => {
    const newFiles = files.filter(file => file !== f)
    onChange(newFiles.join(','))
  }
  return (
    <div>
      <div>
        {title}
      </div>
      <div >
        {
          files.length ?
            <div>
              <div style={{ display: 'flex' }}>
                {
                  files.map(img => {
                    const src = Constants.POST_IMAGES_PATH + img;
                    return (
                      <div key={img}  style={{ display: 'flex', alignItems: 'end', marginRight: 10, background: 'whitesmoke', padding: '0.5rem' }}>
                        <img src={src} style={style} />
                        {
                          edit ?
                          <>
                            <button style={{ color: 'white', background: 'red', border: 'none' }} onClick={() =>handleRemoveImages(img)}>X</button><br />
                          </> : null
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
            :
            <span>No image</span>
        }{}
      </div>
    </div>
  );
};
