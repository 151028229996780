import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Utils } from "./Utils";
import "./App.css";
import { Container, Col, Row, Nav, Navbar, Card } from "react-bootstrap";
import logo from "./assets/images/Logo1.png"
import "bootstrap/dist/css/bootstrap.min.css";
import createPost from "./assets/videos/create_post.mp4";
import editDeletePost from "./assets/videos/edit_delete.mp4"
import changePassword from "./assets/videos/change_password.mp4"




export class HowToBot extends Component {
  displayHome = () => {
    return (
      <div>
        <div>
          <Navbar expand="lg" style={{ padding: "20px" }}>
            <Navbar.Brand href="#home" style={{ "margin-left": "50px" }}>
              <img
                style={{ width: "158.44px", height: "35.37px", "margin-left": "10px" }}
                alt="icon"
                src={logo}
                className="d-inline-block align-top"
              ></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
              <Nav >
                <Nav.Link href="#home" className="App-Nav-link" >HOME</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div >
          <Container style={{ "margin-top": "40px" }}>
            <h1 className="howToBot_section_heading" >Tutorials</h1>
            <h2 className="howToBot_section_tagline">Learn here how to bot your advertisment</h2>

            {/* <h2 className="howToBot_section_tagline red-text">Videos will be upload soon</h2> */}


            <Row style={{ "margin-top": "50px", "margin-bottom": "50px" }} >

              <Col xs={12} lg={5} md={5} >

                <Card className="howToBot_card" border="light">
                  {/* <Card.Img variant="top" as="<video>" src={createPost} /> */}

                  <video width="100%" height="100%" controls style={{ border: '0px solid #94dade' }}>
                    <source src={createPost} type="video/mp4"></source>
                  </video>

                  <Card.Body>
                    <Card.Title className="howToBot_card-title">1. Create Post</Card.Title>
                  </Card.Body>
                </Card>
              </Col>


              <Col xs={0} lg={2} md={2}>
              </Col>


              <Col xs={12} lg={5} md={5} >
                <Card className="howToBot_card" border="light" >

                  <video width="100%" height="100%" controls style={{ border: '0px solid #94dade' }}>
                    <source src={editDeletePost} type="video/mp4"></source>
                  </video>


                  <Card.Body>
                    <Card.Title className="howToBot_card-title">2. Edit / Delete Post</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              
              <Col xs={12} lg={5} md={5} >
                <Card className="howToBot_card" border="light" >
                 
                <video width="100%" height="100%" controls style={{ border: '0px solid #94dade' }}>
                    <source src={changePassword} type="video/mp4"></source>
                  </video>

                  <Card.Body>
                    <Card.Title className="howToBot_card-title">3. Change Password</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
          {/*
              <Col xs={0} lg={2} md={2}>
              </Col>

              <Col xs={12} lg={5} md={5}>
                <Card className="howToBot_card" border="light"  >
                  <Card.Img variant="top" className="card_img" src="/rect4.png" />
                  <Card.Body>
                    <Card.Title className="howToBot_card-title">4. How to do</Card.Title>
                  </Card.Body>
                </Card>
              </Col> */}

            </Row>
          </Container>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div>
        {Utils.isLoggedIn() ? <Redirect to="dashboard" /> : this.displayHome()}
      </div>
    );
  }
}
