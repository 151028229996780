import React from "react";
import { AppBar, UserMenu, MenuItemLink, Layout } from "react-admin";
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import Menu from "./Menu";

const UpdatePassword = React.forwardRef(({ onClick }, ref) => (
  <MenuItemLink
    ref={ref}
    to="/update_password"
    primaryText="Update Password"
    leftIcon={<SettingsApplicationsIcon />}
    onClick={onClick} // close the menu on click
  />
));

const EditProfile = React.forwardRef(({ onClick }, ref) => (
  <MenuItemLink
    ref={ref}
    to="/edit_profile"
    primaryText="Edit Profile"
    leftIcon={<PermIdentityIcon />}
    onClick={onClick} // close the menu on click
  />
));

const MyUserMenu = (props) => (
  <UserMenu {...props}>
    <EditProfile />
    <UpdatePassword />
  </UserMenu>
);

const MyAppBar = (props) => <AppBar {...props} userMenu={<MyUserMenu />} />;

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} menu={Menu} />;

export default MyLayout;
