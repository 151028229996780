import {
  SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardPostalCodeInput,
  CreditCardCVVInput,
  CreditCardSubmitButton,
} from "react-square-payment-form";
import "react-square-payment-form/lib/default.css";
import React, { Component } from "react";
import { Constants } from "./Constants";
import Axios from "axios";
import { Utils } from "./Utils";

class SquarePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
    };
  }

  cardNonceResponseReceived = (
    errors,
    nonce,
    cardData,
    buyerVerificationToken
  ) => {
    if (errors) {
      this.setState({
        errorMessages: errors.map((error) => error.message),
      });
      return;
    }

    this.setState({
      errorMessages: [],
    });
    this.sendToBE(nonce, buyerVerificationToken);
  };

  sendToBE = (nonce, buyerVerificationToken) => {
    let reqBody = JSON.stringify({
      nonce: nonce,
      token: buyerVerificationToken, //ADD this line
    });
    return Axios.post(Constants.BASE_URL + "cards", reqBody, {
      headers: Constants.REQUEST_HEADER_JSON,
    })
      .then((response) => {
        this.state.errorMessages.push("Success");
        this.setState({ errorMessages: this.state.errorMessages });
        window.location.href = "#cards";
      })
      .catch((error) => {
        this.state.errorMessages.push(error.message);
        this.setState({ errorMessages: this.state.errorMessages });
      });
  };

  createVerificationDetails = () => {
    return {
      intent: "STORE",
      billingContact: {
        givenName: Utils.getUser().name,
      },
    };
  };

  render() {
    if (Utils.isLoggedIn() === false) {
      return <div></div>;
    }
    return (
      <div>
        <h1> Add Card </h1>

        <SquarePaymentForm
          sandbox={true}
          applicationId={Constants.SANDBOX_APPLICATION_ID}
          locationId={Constants.SANDBOX_LOCATION_ID}
          cardNonceResponseReceived={this.cardNonceResponseReceived}
          createVerificationDetails={this.createVerificationDetails}
        >
          <fieldset className="sq-fieldset">
            <CreditCardNumberInput />
            <div className="sq-form-third">
              <CreditCardExpirationDateInput />
            </div>

            <div className="sq-form-third">
              <CreditCardPostalCodeInput />
            </div>

            <div className="sq-form-third">
              <CreditCardCVVInput />
            </div>
          </fieldset>

          <CreditCardSubmitButton>Add Card</CreditCardSubmitButton>
        </SquarePaymentForm>

        <p>Note: This card information will be saved for furure payments</p>

        <div className="sq-error-message">
          {" "}
          {this.state.errorMessages.map((errorMessage) => (
            <li key={`sq-error-${errorMessage}`}> {errorMessage} </li>
          ))}{" "}
        </div>
      </div>
    );
  }
}

export default SquarePayment;
