import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import SigninDialog from "./signin";
import { Utils } from "./Utils";
import SignupDialog from "./signup";
import "./App.css";
import { Container, Col, Row, Nav, Navbar, Button, Form } from "react-bootstrap";
import videoADI from "./assets/videos/ADI.mp4"
import logo from "./assets/images/Logo1.png"
import bannerMobile from "./assets/images/Group-9.png"
import aboutUs from "./assets/images/about-us.png"
import freeSignUp from "./assets/images/free-sign-up.png"
import helpForm from "./assets/images/help_form.png"
import logoWhite from "./assets/images/logo_white.png"

import "bootstrap/dist/css/bootstrap.min.css";

export class Home extends Component {
  displayHome = () => {
    return (
      <div>
        <div>
          <Navbar expand="lg" style={{ padding: "20px" }}>
            <Navbar.Brand href="#home" style={{ "margin-left": "50px" }}>
              <img
                style={{ width: "158.44px", height: "35.37px", "margin-left": "10px" }}
                alt="icon"
                src={logo}
                className="d-inline-block align-top"
              ></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
              <Nav >
                <Nav.Link href="#" className="App-Nav-link" >
                  <SigninDialog
                    title="SIGN IN"
                    signButtons="signin-buttons"
                  />
                </Nav.Link>
                <Nav.Link href="#" className="App-Nav-link" >
                  <SignupDialog
                    signupButtons="signin-buttons"
                    title="SIGN UP"
                  />
                </Nav.Link>
                <Nav.Link href="#how-to-bot" className="App-Nav-link" >HOW TO BOT</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div
          className="my-Cont">
          <Container fluid>
            <Row>
              <Col xs={0} md={1} lg={1}></Col>
              <Col xs={12} md={5} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                <div className="App-banner">
                  <h1>AUTOMATE YOUR AD POSTING</h1>
                  <p className="app-hero-paragraph">
                    Easy Ad Poster act as your personalized online portal which integrates Craigslist, offer up and let go applications in a single platform with a wow-factor design that offers outstanding ad posting services to the customers. Just register your account on Easy Ad Poster applications and enjoy a great customer experience with our tailor-made ad posting services. </p>
                  <p className="app-hero-button">
                    <SignupDialog
                      signupButtons="signin-buttons"
                      title="SIGN UP"
                      className="signup-button"
                    />
                  </p>
                </div>
              </Col>
              <Col xs={0} md={1} lg={2}>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <img
                  style={{ height: "450px ", "width": "100%" }}
                  alt="icon"
                  src={bannerMobile}
                ></img>
              </Col>
              <Col xs={0} md={0} lg={1}></Col>
            </Row>
          </Container>
        </div>
        <div >
          <Container style={{ "margin-top": "50px" }}>
            <Row style={{ backgroundColor: 'white' }}>
              <Col lg={3} sm={0}></Col>
              <Col lg={6} sm={12}>
                <h1 className="section_heading">FAST AUTOMATION</h1>
                <div className="auto-post-img"></div>
              </Col>
              <Col lg={3} sm={0}></Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container style={{}}>
            <h1 className="section_heading">ABOUT US</h1>
            <Row style={{ backgroundColor: 'white', marginTop: 30 }}>
              <Col md={5} xs={12} style={{
                display: 'flex', justifyContent: 'center'
              }}>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  Easy Ad Poster is an automated service application, exclusively designed to give you the best ad posting service experience. The objective of this application is to provide you with a unique platform with a comprehensive package to promote and advertise products. Its interface design is incredibly user- friendly which shows various tabs and bulletin-board like news feed of items for posting an ad within the desired area.
              </p>
              </Col>
              <Col md={3} xs={0} >
              </Col>
              <Col md={4} xs={12} style={{
                display: 'flex', justifyContent: 'center'
              }}>
                <img
                  style={{ "height": "100%", "width": "215px" }}
                  alt="icon"
                  src={aboutUs}
                ></img>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <div style={{ textAlign: 'center' }}>
            <h1 className="section_heading" style={{ marginBottom: 30, marginTop: 30 }}>Hello ADI!</h1>
            <div style={{ background: '#94dade', paddingTop: 30 }}>
              <video width="80%" controls style={{ border: '2px solid #94dade' }}>
                <source src={videoADI} type="video/mp4"></source>
              </video>
              <h3 className="section_second_heading">DO YOU WANT TO INCREASE YOUR SALES?</h3>
            </div>
          </div>
        </div>
        <div style={{ "padding-top": "50px" }}>
          <Container style={{}}>
            <Row style={{ backgroundColor: 'white' }}>
              <Col md={6} xs={12} style={{
                display: 'flex', justifyContent: 'center',
                "align-items": "center"
              }}>
                <div >
                  <div style={{
                    display: 'flex', justifyContent: 'center'
                  }}>
                    <span style={{
                      "font-size": "100px",
                      "font-family": "'Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
                      "line-height": "64px", "font-weight": "bold"
                    }}>FREE</span></div>
                  <div style={{
                    display: 'flex', justifyContent: 'center',
                    "font-size": "55px",
                    "font-weight": "bold"
                  }}><span>SIGN UP</span></div>
                  <div style={{
                    display: 'flex', justifyContent: 'center',
                  }}>
                    <p className="free-sign-up-btn">
                      <SignupDialog
                        signupButtons="signin-buttons"
                        title="CLICK HERE"
                        className="signup-button"
                      />
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={1} xs={0} >
              </Col>
              <Col md={5} xs={12} style={{
                display: 'flex', justifyContent: 'center'
              }}>
                <img
                  style={{ "height": "340px" }}
                  alt="icon"
                  src={freeSignUp}
                ></img>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ "padding-top": "50px", "padding-bottom": "50px" }}>
          <Container >
            <div><h1 className="section_heading">Hello, I’m ADI
                <img
                style={{}}
                alt="icon"
                src={helpForm}
              ></img>
            </h1>
            </div>
            <Row style={{ backgroundColor: 'white' }}>
              <Col lg={2} md={4} xs={12}>
                <h3 style={{
                  "font-style": "normal", "text-align": "center", "font-weight": "bold", "font-size": "38px", "color": "#07344D",
                  "text-shadow": "4px 4px 8px rgba(0, 0, 0, 0.25)",
                  "padding-bottom": "20px",
                  "padding-top": "20px"
                }}>How can I help you?</h3>
              </Col>
              <Col lg={8} md={8} xs={12} >
                <Form>
                  <Row>
                    <Col>
                      <Form.Group controlId="help_name">
                        <Form.Control type="text" placeholder="Name" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="help_email">
                        <Form.Control type="email" placeholder="Email" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="help_message">
                        <Form.Control as="textarea" placeholder="Your Message" rows="9" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button variant="dark" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col lg={2} md={0} xs={0} >
              </Col>
            </Row>
          </Container>
        </div>
        <footer id="footer">
          <div className="inner">
            <div className="copyright">
              &copy; Design by :
                <a href="http://knightssystem.com/"> KNIGHTSSYSTEM</a>.
              </div>
          </div>
        </footer>
        <div style={{ "background-color": "#08344D" }}>
          <Container >
            <Row style={{ "border-bottom": "2px solid #FFFFFF" }}>
              <Col md={3} xs={12} style={{ "display": "flex", "justify-content": "center", "align-items": "center" }}>

                <img
                  style={{ width: "167.44px" }}
                  alt="icon"
                  src={logoWhite}

                ></img>
              </Col>
              <Col md={6} xs={12} >
                <h3 style={{
                  "font-style": "normal", "text-align": "center", "font-weight": "bold", "font-size": "22px", "color": "#fff",
                  "text-shadow": "4px 4px 8px rgba(0, 0, 0, 0.25)",
                  "padding-bottom": "20px",
                  "padding-top": "20px",

                }}>sales@easyadposter.com</h3>
              </Col>
              <Col md={3} xs={12} style={{ "display": "flex", "justify-content": "center" }}>
              </Col>
            </Row>
            <Row >
              <Col md={3} xs={0}>
              </Col>
              <Col md={6} xs={0} >
                <h3 style={{
                  "font-style": "normal", "text-align": "center", "font-weight": "bold", "font-size": "15px", "color": "#fff",
                  "text-shadow": "4px 4px 8px rgba(0, 0, 0, 0.25)",
                  "padding-bottom": "20px",
                  "padding-top": "20px",

                }}>2020&copy; EasyAdPoster</h3>
              </Col>
              <Col md={3} xs={0} >
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div>
        {Utils.isLoggedIn() ? <Redirect to="dashboard" /> : this.displayHome()}
      </div>
    );
  }
}
