import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextInput,
  PasswordInput,
  Create,
} from 'react-admin';
import { BooleanNumField } from './booleanNumField';

export const UserList = (props) => (
  <List {...props} bulkActionButtons={false} actions={<div></div>}>
    <Datagrid rowClick="edit">
      <TextField source="email" />
      <BooleanNumField source="start_posting" label="Posting On"/>
      <BooleanNumField source="isLoginFailed" label="Last Login Failed" />
    </Datagrid>
  </List>
);

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="email" />
      <TextInput source="password" />
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Create>
);
