import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Utils } from "./Utils";
import { Constants } from "./Constants";
import { useAuthenticated } from "react-admin";
import Axios from "axios";
import { SimpleForm, TextInput, useNotify, useRedirect, Toolbar } from "react-admin";


const EditProfile = () => {
  useAuthenticated();
  const notify = useNotify();
  const redirect = useRedirect();

  const [state, setState] = React.useState({
    name: '', email: '', mobileNum: '',
    name_error: false, email_error: false, mobileNum_error: false,
    name_error_text: '', email_error_text: '', mobileNum_error_text: ''

  });

  useEffect(() => {
    Axios.get(Constants.BASE_URL + "users/info", {
      headers: Constants.REQUEST_HEADER_JSON,
    })
      .then((response) => {

        const { name, email, phone_no } = response.data;
        setState(prevState => ({
          ...prevState,
          name: name ? name : '',
          email: email ? email : '',
          mobileNum: phone_no ? phone_no : ''

        }));

      })
      .catch((error) => {
        notify("Network Error", 'warning');
      });
  }, []);

  const formDefaultValues = () => ({
    email: state.email,
    name: state.name,
    mobileNum: state.mobileNum
  });

  const handleOnChange = event => {

    const { name, value } = event.target;
    const errors = name + "_error";
    const errors_text = name + "_error_text";

    setState({
      ...state, [name]: value,
      [errors]: false,
      [errors_text]: ""
    });
  };

  const validate = () => {
    let errorValue = false;
    if (state.name === '' || state.mobileNum === '') {
      notify('Input fields cannot be empty.', 'warning');
      errorValue = true;
    }

    if (state.name.length > 2) {
      setState(prevState => ({
        ...prevState, name_error: false,
        name_error_text: ''
      }));
    } else {
      setState(prevState => ({
        ...prevState, name_error: true,
        name_error_text: state.name === '' ? 'Required' : 'Name should contain atleast three characters'
      }));
      errorValue = true;

    }
    if (!(state.mobileNum)) {
      setState(prevState => ({
        ...prevState, mobileNum_error: true,
        mobileNum_error_text: 'Required'
      }));
      errorValue = true;

    }
    const phoneRegex = /^(1|)[2-9]\d{2}[2-9]\d{6}$/;
    let mobile_num = state.mobileNum;
    if (mobile_num.indexOf("00") === 0 || mobile_num.indexOf("1") === 0) {
      if (mobile_num.indexOf("00") === 0) {
        mobile_num = mobile_num.substring(2);
      } else if (mobile_num.indexOf("+") === 0) {
        mobile_num = mobile_num.substring(1);
      }
      if (!(phoneRegex.test(mobile_num))) {
        setState(prevState => ({
          ...prevState, mobileNum_error: true,
          mobileNum_error_text: "Mobile number is not correct"
        }));
        errorValue = true;

      } else {
        setState(prevState => ({
          ...prevState, mobileNum_error: false,
          mobileNum_error_text: ""
        }));
      }
    } else {
      setState(prevState => ({
        ...prevState, mobileNum_error: true,
        mobileNum_error_text: "Mobile number is not correct"
      }));
      errorValue = true;

    }
    if (errorValue === true) {
      return false;
    }
    else {
      return true;
    }
  }

  function handleSubmit() {

    if (!validate()) {

      return;
    } else {
      let reqBody = JSON.stringify({
        name: state.name,
        phone_no: state.mobileNum, //ADD this line
      });
      return Axios.put(Constants.BASE_URL + "users/updateUser", reqBody, {
        headers: Constants.REQUEST_HEADER_JSON,
      })
        .then((response) => {
          notify('Profile editted successfully');
          redirect('/');
        })
        .catch((error) => {
          notify('Failed', 'warning');
        });
    }
  }

  if (Utils.isLoggedIn() === false) {
    return <div></div>;
  }
  return (
    <Card>
      <CardHeader title="Edit Profile" />
      <CardContent>
        <div>
          <SimpleForm save={handleSubmit} toolbar={<Toolbar alwaysEnableSaveButton />} initialValues={formDefaultValues} >
            <TextInput label="Email" name="email" margin="dense" error={state.email_error} helperText={state.email_error_text} value={state.email} source="email" disabled variant="filled" />
            <TextInput label="Name" name="name" margin="dense" error={state.name_error} helperText={state.name_error_text} onChange={handleOnChange} value={state.name} source="name" variant="filled" />
            <TextInput label="Mobile Number" name="mobileNum" type="number" margin="dense" error={state.mobileNum_error} helperText={state.mobileNum_error_text} onChange={handleOnChange} variant="filled" source="phone_no" />
            <div id="response"></div>
          </SimpleForm>
        </div>
      </CardContent>
    </Card>
  );
};

export default EditProfile;
