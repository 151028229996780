// in src/App.js
import React from "react";
import { Admin, Resource } from "react-admin";
import { UserList, UserEdit, UserCreate } from "./users";
import { PostList, PostEdit, PostCreate } from "./posts";
import { PostLogList } from "./postLogs";
import { TransactionLogList } from "./transactionLogs";
import { CardList } from "./cards";
import myDataProvider from "./myDataProvider";
import authProvider from "./authProvider";
import axios from "axios";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PostAddIcon from '@material-ui/icons/PostAdd';
import BarChartIcon from '@material-ui/icons/BarChart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SquarePayment from "./SquarePayment";
import MyLoginPage from "./MyLoginPage";
import customRoutes from "./customRoutes";
import MyLayout from "./MyLayout";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

axios.interceptors.request.use(function (config) {
  config.headers["x-access-token"] = localStorage.getItem("token");
  return config;
});

const dataProvider = myDataProvider;
const App = () => (
  <div>
    <Admin
      layout={MyLayout}
      loginPage={MyLoginPage}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dataProvider={dataProvider}
    >
      <Resource
        options={{ label: "Add Card" }}
        name="cards"
        list={CardList}
        create={SquarePayment}
        icon={AccountBalanceIcon}
      />
      <Resource
        options={{ label: "Craiglist Account" }}
        name="craiglistUsers"
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        icon={AccountCircleIcon}
      />
      <Resource
        options={{ label: "Offerup Account" }}
        name="offerupUsers"
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        icon={AccountCircleIcon}
      />
      <Resource
        options={{ label: "All Posts" }}
        name="posts"
        list={PostList}
        edit={PostEdit}
        create={PostCreate}
        icon={PostAddIcon}
      />
      <Resource
        options={{ label: "Post Logs" }}
        name="postLogs"
        list={PostLogList}
        icon={BarChartIcon}
      />
      <Resource
        options={{ label: "Payments" }}
        name="transactionLogs"
        list={TransactionLogList}
        icon={AccountBalanceWalletIcon}
      />
    </Admin>
  </div>
);
export default App;
