import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Constants } from "./Constants";
import { SimpleForm, PasswordInput , useNotify, useRedirect} from "react-admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

export default function SignupDialog(props) {

  const [open, setOpen] = React.useState(false);
  const [acceptCTerms, setAcceptCTerms] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const [state, setState] = React.useState({
    name: '', email: '', password: '', confPassword: '', mobileNum: '', response: '',
    name_error: false, email_error: false, password_error: false, confPassword_error: false, mobileNum_error: false,
    name_error_text: "", email_error_text: "", password_error_text: "", confPassword_error_text: "", mobileNum_error_text: "",
    showPassword: false,showConfPassword: false
  });

  const handleOnChange = event => {
    const { name, value } = event.target;
    const errors = name + "_error";
    const errors_text = name + "_error_text";

    setState({
      ...state, [name]: value,
      [errors]: false,
      [errors_text]: ""
    });
  };

  const validate = () => {
    let errorValue = false;

    if (state.name === "" || state.email === "" || state.password === "" || state.confPassword === "" || state.mobileNum === "") {
      setState(prevState => ({ ...prevState, response: "Input fields cannot be empty." }));
      errorValue = true;


    }
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (state.name.length > 2) {
      setState(prevState => ({
        ...prevState, name_error: false,
        name_error_text: ''
      }));
   } else {
      setState(prevState => ({
        ...prevState, name_error: true,
        name_error_text: state.name === '' ? 'Required' : 'Name should contain atleast three characters'
      }));
      errorValue = true;
    }
    if (state.email && emailRegex.test(state.email)) {
      setState(prevState => ({
        ...prevState, email_error: false,
        email_error_text: ""
      }));
    } else {
      // email_err = true;
      setState(prevState => ({
        ...prevState, email_error: true,
        email_error_text: state.email ? "Invalid email address" : 'Required'
      }));
      errorValue = true;
    }
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (passwordRegex.test(state.password)) {
      setState(prevState => ({
        ...prevState, password_error: false,
        password_error_text: ""
      }));
    } else {
      // email_err = true;
      setState(prevState => ({
        ...prevState, password_error: true,
        password_error_text: state.password ? 'Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character' : 'Required'
      }));
      errorValue = true;
    }
    if (state.password !== state.confPassword) {
      setState(prevState => ({
        ...prevState, confPassword_error: true,
        confPassword_error_text: "Password does not match"
      }));
      errorValue = true;
    } else {
      setState(prevState => ({
        ...prevState, confPassword_error: false,
        confPassword_error_text: ""
      }));
    }
    if (acceptCTerms === false) {
      setState(prevState => ({ ...prevState, response: "Terms and conditons are not accepted." }));
      errorValue = true;
    }
    
    const phoneRegex = /^(1|)[2-9]\d{2}[2-9]\d{6}$/;
    let mobile_num = state.mobileNum;
    if (mobile_num.indexOf("00") === 0 || mobile_num.indexOf("1") === 0) {
      if (mobile_num.indexOf("00") === 0) {
        mobile_num = mobile_num.substring(2);
      } else if (mobile_num.indexOf("+") === 0) {
        mobile_num = mobile_num.substring(1);
      }
      if (!(phoneRegex.test(mobile_num))) {
        setState(prevState => ({
          ...prevState, mobileNum_error: true,
          mobileNum_error_text: "Mobile number is not correct"
        }));
        errorValue = true;
      } else {
        setState(prevState => ({
          ...prevState, mobileNum_error: false,
          mobileNum_error_text: ""
        }));
      }
    } else {
      // email_err = true;
      setState(prevState => ({
        ...prevState, mobileNum_error: true,
        mobileNum_error_text: "Mobile number is not correct"
      }));
      errorValue = true;
    }
    if(errorValue === true){  
    return false;
    }
    else {
      return true;
    }
  }

  const handleShow = () => {
    setShow(true);
  };
  const handleCloseTerm = () => {
    setShow(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickTerms = () => {
    setAcceptCTerms(!acceptCTerms);
  };

  const handleAcceptTerms = () => {
    setAcceptCTerms(true);
    setShow(false);
  };

  const handleRejectTerms = () => {
    setAcceptCTerms(false);
    setShow(false);
  };

  const handleClickShowPassword = () => {
  
    setState(prevState => ({ ...prevState, showPassword: !state.showPassword}));

  };

  const handleClickShowConfPassword = () => {
  
    setState(prevState => ({ ...prevState, showConfPassword: !state.showConfPassword}));

  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validate()) {
      
      setState(prevState => ({ ...prevState, response: "Invalid Form" }));
      return;
    } else {
      
      const request = new Request(Constants.BASE_URL + "users", {
        method: "POST",
        body: JSON.stringify({ name: state.name, email: state.email, password: state.password, phone_no: state.mobileNum }),
        headers: new Headers({ "Content-Type": "application/json" }),
      });
      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            return response.json();
          }
          return {};
        })
        .then((data) => {
          let em = data.error ? data.error : "Success";
          document.getElementById("response").innerText = em;
          if (em === "Success") {
            document.getElementById("signin_button").click();
            setOpen(false);
          }
        });
    }
  };


  
  return (
    <>
      <span className={props.className} onClick={handleClickOpen}>
        {props.title}
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Register</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          {/* <SimpleForm save={handleSubmit}>
        <PasswordInput label="Current password" name="currentPass" error={state.currentPass_error} helperText={state.currentPass_error_text} onChange={handleOnChange} value={state.currentPass}  source="old_password" />
        <PasswordInput label="New password" name="newPass" error={state.newPass_error} helperText={state.newPass_error_text} onChange={handleOnChange} value={state.newPass} source="new_password" />
        <PasswordInput label="Confirm password"  name="confNewPass" error={state.confNewPass_error} helperText={state.confNewPass_error_text} onChange={handleOnChange} value={state.confNewPass}  source="re_new_password" />
        <div id="response">{state.response}</div>
      </SimpleForm> */}
      
          <form
            id="signup_form"
          >

            <TextField
              value={state.name}
              name="name"
              onChange={handleOnChange}
              required
              autoFocus
              error={state.name_error}
              helperText={state.name_error_text}
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
            />
            <TextField
              value={state.email}
              name="email"
              onChange={handleOnChange}
              required
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              error={state.email_error}
              helperText={state.email_error_text}

            />
            <TextField
              value={state.password}
              onChange={handleOnChange}
              name="password"
              required
              margin="dense"
              id="password"
              label="Password"
              type={state.showPassword ? 'text' : 'password'}
              fullWidth
              error={state.password_error}
              helperText={state.password_error_text}              
              InputProps={{
                endAdornment:  <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
              }}
            />

            <TextField
              value={state.confPassword}
              onChange={handleOnChange}
              name="confPassword"
              required
              margin="dense"
              id="re_password"
              label="Confirm Password"
              type={state.showConfPassword ? 'text' : 'password'}
              fullWidth
              error={state.confPassword_error}
              helperText={state.confPassword_error_text}            
              InputProps={{
                endAdornment:  <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {state.showConfPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
              }}
            />

            <TextField
              value={state.mobileNum}
              onChange={handleOnChange}
              name="mobileNum"
              required
              margin="dense"
              id="mobile_number"
              label="Mobile Number"
              type="number"
              placeholder="+12025550163"
              fullWidth
              error={state.mobileNum_error}
              helperText={state.mobileNum_error_text}

            />
            <div>
              <FontAwesomeIcon
                required
                className="hoverPointer"
                onClick={handleClickTerms}
                style={{
                  color: acceptCTerms ? "green" : "grey",
                  fontSize: 16,
                }}
                icon={acceptCTerms ? faCheckCircle : faDotCircle}
              />
              <span
                className="hoverPointer"
                onClick={handleClickTerms}
                style={{ fontWeight: 400, fontSize: 16, marginLeft: 2 }}
              >
                I accept
              </span>
              <span
                className="hoverPointer"
                onClick={handleShow}
                style={{
                  marginLeft: 2,
                  textDecoration: "underline",
                  color: "blue",
                }}
              >
                Terms and conditions
              </span>
            </div>
            <div
              style={{
                fontSize: 12,
                color: "#198d93",
              }}
            >
              * Please read and accept the term and condition before sign up
            </div>


            <div id="response" style={{ color: 'red' }}>{state.response}</div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={props.signupButtons}>
            Cancel
          </Button>
          <Button
            type="submit"
            form="signup_form"
            className={props.signupButtons}
            // onClick={acceptCTerms ? handleSubmit : () => {}}
            onClick={handleSubmit}

          >
            Signup
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={show} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Terms And Conditons</DialogTitle>
        <DialogContent>
          <DialogContentText>Conditions</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRejectTerms} className={props.signupButtons}>
            Dicline
          </Button>
          <Button onClick={handleAcceptTerms} className={props.signupButtons}>
            I accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
