import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Constants } from "./Constants";
import { Link } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';


export default function SigninDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let email = document.getElementById("email").value;
    let password = document.getElementById("password").value;
    //setOpen(false);

    if (email === "" || password === "") {
      return;
    }

    const request = new Request(Constants.BASE_URL + "users", {
      method: "PUT",
      body: JSON.stringify({ email: email, password: password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return { error: "Invalid Login" };
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        let em = data.error ? data.error : "Success";
        document.getElementById("response").innerText = em;
        if (!data.error) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data));
          window.location.reload(false);
        }
      });
  };

  return (
    <>
      <span
        id="signin_button"
        className={props.className}
        onClick={handleClickOpen}
      >
        {props.title}
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <form id="signin_form">
            <TextField
              required
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
            />
            <TextField
              required
              margin="dense"
              id="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              fullWidth

              InputProps={{
                endAdornment:  <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
              }}
            />
            <Link href="#reset_password">Reset Password</Link>
          </form>
          <div id="response" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={props.signButtons}>
            Cancel
          </Button>
          <Button
            type="submit"
            form="signin_form"
            className={props.signButtons}
            onClick={handleSubmit}
          >
            Signin
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
