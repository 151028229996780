import React from "react";
import { List, Datagrid, DateField, TextField, NumberField } from "react-admin";

export const CardList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid actions="">
      <TextField source="id" />
      <NumberField source="card_id" />
      <NumberField source="user_id" />
      <TextField source="billing_customer_id" />
      <TextField source="card_brand" />
      <TextField source="last_4" />
      <TextField source="exp_month" />
      <TextField source="exp_year" />
      <DateField source="updatedAt" showTime={true} />
      <DateField source="createdAt" showTime={true} />
    </Datagrid>
  </List>
);
