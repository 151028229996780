import React from "react";
import { List, Datagrid, DateField, TextField, NumberField } from "react-admin";

export const TransactionLogList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid actions="">
      <TextField source="id" />
      <NumberField source="payment_id" />
      <TextField source="status" />
      <NumberField source="amount" />
      <TextField source="billing_customer_id" />
      <TextField source="billing_card_id" />
      <DateField source="updatedAt" showTime={true} />
      <DateField source="createdAt" showTime={true} />
    </Datagrid>
  </List>
);
