export class Constants {
  static BASE_URL =
    process.env.REACT_APP_ECOMMERCE_BE_URL ||
    'https://backend.easyadposter.com/';
  static BASE_URL_2 =
    process.env.REACT_APP_ECOMMERCE_BE_URL ||
    'https://backend.easyadposter.com';
  static REQUEST_HEADER_JSON = { 'Content-Type': 'application/json' };
  static POST_IMAGES_PATH = Constants.BASE_URL + 'files/post_images/';
  static POST_IMAGES_API_PATH =
    Constants.BASE_URL + 'files/upload/post_images/';
  static SANDBOX_APPLICATION_ID = 'sandbox-sq0idb-YqFQ6SnVRSZsPJSUAzu3yA';
  static SANDBOX_LOCATION_ID = 'KZ7F0QK050HGG';
}
