import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Utils } from "./Utils";
import { useAuthenticated } from "react-admin";

const messageAddCard = () => {
  return (
    <div>
      Please <a href="#cards/create">Add Card</a>. A charge of 99$ will be
      deducted with in 2 hours.
    </div>
  );
};
const isCardAdded = () => {
  return !(
    Utils.getUser().billing_card_id === undefined ||
    Utils.getUser().billing_card_id === null
  );
};
const Dashboard = () => {
  useAuthenticated();
  if (Utils.isLoggedIn() === false) {
    return <div></div>;
  }
  return (
    <Card>
      <CardHeader title="Welcome to EasyAdPoster" />
      <CardContent>
        Hello <b>{Utils.getUser().name}</b>.
        {isCardAdded() ? <div>Card has been added</div> : messageAddCard()}
        <div>
          Admin will contact you (if not done yet) within 2 business days after
          successful payment for initial setup.
        </div>
      </CardContent>
    </Card>
  );
};

export default Dashboard;
